import about1 from "../images/about1.png";
import about2 from "../images/about2.svg";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import Footer from "../components/Footer";
import "../style/quotation.css";
import Navbar from "../components/Navbar";
import { BsCheck } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { requestQuotation } from "../api";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import Success from "../components/Success";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Quotation() {
  const { t, i18n } = useTranslation();
  const [checkList, setCheckList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const source = queryParams.get('source');

  // // Now 'source' contains the value indicating the button clicked
  // console.log('Source:', source);

  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = "عرض سعر";
    } else {
      document.title = "QUOTATIONS";
    }
  }, []);

  const Schema = yup.object().shape({
    first_name: yup.string().required("validation.first"),
    last_name: yup.string().required("validation.last"),
    company_name: yup.string().required("validation.company"),
    phone: yup
      .string()
      .required("validation.phone")
      .min(10, "validation.phoneInvalid"),
    email: yup
      .string()
      .required("validation.email")
      .matches(emailRegx, "validation.emailInvalid"),
    message: yup.string().required("validation.message"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const source = localStorage.getItem("source");

  function onSubmit() {
    if (checkList.length == 0) {
      setErr(true);
      setErrMsg(
        i18n.language == "ar"
          ? "تحتاج إلى تحديد الخدمة"
          : "You need to select a service"
      );
    } else {
      setLoading(true);
      const data = {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        company_name: formik.values.company_name,
        phone: formik.values.phone,
        email: formik.values.email,
        content: formik.values.message,
        // services: checkList.toString(),
        services: checkList,
        type: "service",
        source: source,
      };

      requestQuotation(data, i18n.language)
        .then((response) => {
          setLoading(false);
          setErr(false);
          setSuccess(true);
          const checkboxes = document.getElementsByClassName("chooseService");

          for (let i = 0; i < checkboxes.length; i++) {
            const checkbox = checkboxes[i];

            if (checkbox.checked) {
              checkbox.checked = false;
            }
          }
          setCheckList([]);
          /*var frm = document.getElementsByName('quotationFrm')[0];
frm.reset(); */
          formik.handleReset();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }

  function handleCheck(value) {
    const newArr = checkList;
    if (newArr.includes(value)) {
      newArr.splice(newArr.indexOf(value), 1); // Remove value
    } else {
      newArr.push(value); // Add value
    }
    setCheckList(newArr);
  }
  return (
    <div className="aboutUs-container">
      <Helmet>
        <title>Bdaiat Technologies - Quotation</title>
        <link rel="canonical" href="https://www.bdaiat.com/quotation" />

        <script type="application/ld+json">{`
          {"@context":"http://schema.org","@type":"Organization","name":"Bdaiat Technolgoies","@id":"BdaiatTechnologies","url":"https://www.bdaiat.com/","logo":"https://www.bdaiat.com/bdaiat-logo.png","address":{"@type":"PostalAddress","addressLocality":"Sidi Gaber","addressRegion":"Alexandria","postalCode":"21523","streetAddress":"Building #378 Horya Road, Sidi Gaber, Alexandria, Egypt","addressCountry":"Egypt"},"email":"mailto:info@bdaiat.com","contactPoint":[{"@type":"ContactPoint","telephone":"+201097101006","contactType":"Technical Support"}],"sameAs":["https://www.facebook.com/bdaiat","https://www.instagram.com/bdaiat/","https://twitter.com/BdaiatTech","https://www.linkedin.com/company/bdaiat"]}
      `}</script>
      </Helmet>
      {success ? <Success type="success" setModal={setSuccess} /> : null}

      <div className=" container-fluid quotation-container">
        <div className="row">
          <div className="col-lg-6 col-md-7 quotationForm">
            <form onSubmit={formik.handleSubmit} name="quotationFrm">
              <p id="quotRequest">{t("navbar.quotation")}</p>
              <p id="quotReach">
                {t("services.reach")}{" "}
                <a href={`mailto:ab.yakout@bdaiat.com`}>ab.yakout@bdaiat.com</a>
                {/* <a href={`mailto:sales@bdaiat.com`}>sales@bdaiat.com</a> */}
              </p>
              <div className=" container-fluid">
                <div className="row">
                  <div className="col-6  nopadding">
                    <label>{t("services.name")}</label>
                    <input
                      type="text"
                      placeholder={t("services.first")}
                      value={formik.values.first_name}
                      id="first_name"
                      name="first_name"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.first_name && formik.touched.first_name
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.first_name && formik.touched.first_name
                        ? t(`${formik.errors.first_name}`)
                        : null}
                    </p>
                  </div>
                  <div className="col-6 nopadding">
                    <label>{t("quotation.last")}</label>
                    <input
                      type="text"
                      placeholder={t("quotation.last")}
                      value={formik.values.last_name}
                      id="last_name"
                      name="last_name"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.last_name && formik.touched.last_name
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.last_name && formik.touched.last_name
                        ? t(`${formik.errors.last_name}`)
                        : null}
                    </p>
                  </div>
                  <div className="col-12  nopadding">
                    <label>{t("services.compName")}</label>
                    <input
                      type="text"
                      placeholder={t("services.compName")}
                      value={formik.values.company_name}
                      id="company_name"
                      name="company_name"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.company_name &&
                        formik.touched.company_name
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.company_name && formik.touched.company_name
                        ? t(`${formik.errors.company_name}`)
                        : null}
                    </p>
                  </div>
                  <div className="col-12 nopadding">
                    <label>{t("services.email")} </label>
                    <input
                      type="text"
                      placeholder="you@company.com"
                      value={formik.values.email}
                      id="email"
                      name="email"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.email && formik.touched.email
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.email && formik.touched.email
                        ? t(`${formik.errors.email}`)
                        : null}
                    </p>
                  </div>
                  <div className="col-12 nopadding">
                    <label>{t("quotation.phone")}</label>

                    <input
                      type="text"
                      placeholder="+1 (555) 000-0000"
                      value={formik.values.phone}
                      id="email"
                      name="phone"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.phone && formik.touched.phone
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.phone && formik.touched.phone
                        ? t(`${formik.errors.phone}`)
                        : null}
                    </p>
                  </div>
                  <div className="col-12 nopadding">
                    <label>{t("services.message")}</label>
                    <textarea
                      type="text"
                      placeholder={t("services.leave")}
                      value={formik.values.message}
                      id="message"
                      name="message"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.message && formik.touched.message
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.message && formik.touched.message
                        ? t(`${formik.errors.message}`)
                        : null}
                    </p>
                  </div>
                  <div className="col-12 nopadding">
                    <p id="services">{t("quotation.services")}</p>
                    <div className=" container-fluid services">
                      <div className="row">
                        <div className="col-sm-6 containCheck  nopadding">
                          <input
                            type="checkbox"
                            className="chooseService"
                            value="1"
                            onChange={(e) => handleCheck(e.target.value)}
                          />
                          <BsCheck color="#1851DF" size={20} />
                          <label>{t("quotation.web")}</label>
                        </div>
                        <div className="col-sm-6 containCheck  nopadding">
                          <input
                            type="checkbox"
                            className="chooseService"
                            value="2"
                            onChange={(e) => handleCheck(e.target.value)}
                          />
                          <BsCheck color="#1851DF" size={20} />
                          <label className="labelCheck">
                            {t("quotation.cc")}
                          </label>
                        </div>
                        <div className="col-sm-6 containCheck  nopadding">
                          <input
                            type="checkbox"
                            className="chooseService"
                            value="3"
                            onChange={(e) => handleCheck(e.target.value)}
                          />
                          <BsCheck color="#1851DF" size={20} />
                          <label className="labelCheck">
                            {t("quotation.ux")}
                          </label>
                        </div>
                        <div className="col-sm-6 containCheck  nopadding">
                          <input
                            type="checkbox"
                            className="chooseService"
                            value="4"
                            onChange={(e) => handleCheck(e.target.value)}
                          />
                          <BsCheck color="#1851DF" size={20} />
                          <label className="labelCheck">
                            {t("quotation.strat")}
                          </label>
                        </div>
                        <div className="col-sm-6 containCheck  nopadding">
                          <input
                            type="checkbox"
                            className="chooseService"
                            value="5"
                            onChange={(e) => handleCheck(e.target.value)}
                          />
                          <BsCheck color="#1851DF" size={20} />
                          <label className="labelCheck">
                            {t("quotation.user")}
                          </label>
                        </div>
                        <div className="col-sm-6 containCheck  nopadding">
                          <input
                            type="checkbox"
                            className="chooseService"
                            value="6"
                            onChange={(e) => handleCheck(e.target.value)}
                          />
                          <BsCheck color="#1851DF" size={20} />
                          <label className="labelCheck">
                            {t("quotation.other")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {err ? <p className="errMsg">{errMsg}</p> : null}
              {loading ? (
                <div
                  className="loaderDiv"
                  style={{ height: "60px", marginTop: "1em" }}
                >
                  <PuffLoader size={40} color="#3B6DEA" />
                </div>
              ) : (
                <button id="submit">{t("quotation.submit")}</button>
              )}
            </form>
          </div>
          <div
            className="col-lg-6 col-md-5  quotImg"
            style={{ padding: "0", paddingBottom: "6vw" }}
          >
            <div style={{ backgroundImage: `url(${about2})` }}></div>
          </div>
        </div>
      </div>

      <div id="quotDiv">
        <p id="contact">{t("quotation.contact")}</p>
        <p id="hear">{t("quotation.hear")}</p>
        <p id="chat">{t("quotation.chat")}</p>
        <div
          className="compImg"
          style={{ backgroundImage: `url(${about1})`, margin: "6vw 0" }}
        ></div>

        <div className=" strech">
          <div className="row  gy-4 contDiv-flex">
            <div className="col-lg-4 col-sm-6 noPadding">
              <div
                className="our contDiv"
                style={
                  i18n.language == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="contactIcon">
                  <HiOutlineChatBubbleLeftRight size={24} />
                </div>
                <div>
                  <p id="contType">{t("quotation.sales")}</p>
                  <p id="contTxt">{t("quotation.speak")}</p>
                  <p id="cont" className="underline">
                    {/* ab.yakout@bdaiat.com */}
                    sales@bdaiat.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 noPadding ">
              <div
                className="our contDiv"
                style={
                  i18n.language == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="contactIcon">
                  <HiOutlineLocationMarker size={24} />
                </div>
                <div>
                  <p id="contType">{t("quotation.visit")}</p>
                  <p id="contTxt">{t("quotation.speak")}</p>
                  <p id="cont">{t("footer.comp2")}</p>
                </div>{" "}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 noPadding">
              <div
                className="our contDiv"
                style={
                  i18n.language == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="contactIcon">
                  <FiPhoneCall size={24} />
                </div>
                <div>
                  <p id="contType">{t("quotation.call")}</p>
                  <p id="contTxt">{t("quotation.speak")}</p>
                  <p id="cont" className="underline">
                    (+2) 0109 710 1006
                  </p>
                  <p id="cont" className="underline">
                    (+2) 0100 075 9105
                  </p>
                  <p id="cont" className="underline">
                    (+966) 53 463 8774
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Quotation;
