import "../style/careers.css";

import Footer from "../components/Footer";

import Navbar from "../components/Navbar";
import graphic from "../images/graphic.svg";
import { BsArrowRightShort } from "react-icons/bs";
import { RiHome6Line } from "react-icons/ri";
import uiuxD from "../images/uiuxD.svg";
import qcD from "../images/qcD.svg";
import mobileD from "../images/mobileD.svg";
import graphicD from "../images/graphicD.svg";
import webD from "../images/webD.svg";

import uiux from "../images/uiux.svg";
import mobile from "../images/mobile.svg";
import web from "../images/web.svg";
import qc from "../images/qc.svg";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineOtherHouses } from "react-icons/md";
import { VscTriangleRight } from "react-icons/vsc";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { serviceQuestion } from "../api";
import Success from "../components/Success";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { useTranslation } from "react-i18next";

// import required modules
import { Navigation } from "swiper";
import { useFormik } from "formik";
import * as yup from "yup";
import { PuffLoader } from "react-spinners";

function ServiceDetails() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const Schema = yup.object().shape({
    name: yup.string().required("validation.name"),
    email: yup
      .string()
      .required("validation.email")
      .matches(emailRegx, "validation.emailInvalid"),
    message: yup.string().required("validation.message"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",

      message: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  function onSubmit() {
    setLoading(true);

    const data = {
      first_name: formik.values.name,
      email: formik.values.email,
      message: formik.values.message,
      type: "service",
    };

    serviceQuestion(data, i18n.language)
      .then((response) => {
        setLoading(false);
        formik.handleReset();

        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  return (
    <div className="aboutUs-container">
      {success ? <Success type="message" setModal={setSuccess} /> : null}

      <div className="mobileNav">
        <p id="aboutNav">
          <Link to="/">
            <RiHome6Line size={20} style={{ color: " #1D2939" }} />
          </Link>{" "}
          <IoIosArrowForward size={25} style={{ margin: "0 0.5em" }} />{" "}
          <Link to="/services" style={{ textDecoration: "none" }}>
            <span style={{ color: " #1D2939" }}>{t("services.services")}</span>
          </Link>{" "}
        </p>
      </div>
      <div id="aboutUsTop">
        <div id="topCover">
          <div>
            <p id="nice">
              {t("services.our")}{" "}
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
            </p>
            <p id="about">{t("services.title")}</p>
            <p id="aboutNav">
              <Link to="/">
                <RiHome6Line size={20} style={{ color: " #1D2939" }} />
              </Link>{" "}
              <IoIosArrowForward size={25} style={{ margin: "0 0.5em" }} />{" "}
              <Link to="/services" style={{ textDecoration: "none" }}>
                <span>{t("services.services")}</span>
              </Link>{" "}
              <IoIosArrowForward style={{ margin: "0 0.5em" }} size={25} />{" "}
              <span>UI/UX Designing</span>{" "}
            </p>
          </div>
          <p id="aboutCompany">{t("services.text")}</p>
        </div>
      </div>

      <div></div>
      <div className="jobDescribe">
        <div className="container-fluid">
          <div className="row gy-4">
            <div className="col-lg-8 col-md-6">
              <p id="jobName">UI/UX Designing</p>

              <p id="jobDetail">
                As a Frontend Engineer, you’ll be architecting and implementing
                the user interface and middle tier of our platforms. You will
                work with all parties in the company to define and provide a
                powerful yet simple and intuitive user interface that will be
                one of the key differentiators of the service in the
                marketplace.
              </p>

              <div className="teamLi">
                <p>
                  <VscTriangleRight size={25} />
                  Lorem ipsum dolor sit amet, consectetur adipiscing.
                </p>
                <p>
                  <VscTriangleRight size={25} />
                  Lorem ipsum dolor sit amet, consectetur adipiscing.
                </p>
                <p>
                  <VscTriangleRight size={25} />
                  Lorem ipsum dolor sit amet, consectetur adipiscing.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <form className="serviceForm" onSubmit={formik.handleSubmit}>
                <p id="jobName" style={{ fontSize: "30px" }}>
                  UI/UX Designing
                </p>
                <p
                  id="quotReach"
                  style={{ fontSize: "14px", marginBottom: "3em" }}
                >
                  {t("services.reach")}{" "}
                  <a href={`mailto:ab.yakout@bdaiat.com`}>
                    ab.yakout@bdaiat.com
                  </a>
                </p>

                <label>{t("services.name")} </label>
                <input
                  type="text"
                  placeholder={t("services.first")}
                  value={formik.values.name}
                  id="name"
                  name="name"
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.name && formik.touched.name ? "error" : null
                  }
                />
                <p className="errMsg">
                  {formik.errors.name && formik.touched.name
                    ? t(`${formik.errors.name}`)
                    : null}
                </p>

                <label>{t("services.email")} </label>
                <input
                  type="text"
                  placeholder="you@company.com"
                  value={formik.values.email}
                  id="email"
                  name="email"
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.email && formik.touched.email ? "error" : null
                  }
                />
                <p className="errMsg">
                  {formik.errors.email && formik.touched.email
                    ? t(`${formik.errors.email}`)
                    : null}
                </p>

                <label>{t("services.message")} </label>
                <textarea
                  type="text"
                  placeholder={t("services.leave")}
                  value={formik.values.message}
                  id="message"
                  name="message"
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.message && formik.touched.message
                      ? "error"
                      : null
                  }
                />
                <p className="errMsg">
                  {formik.errors.message && formik.touched.message
                    ? t(`${formik.errors.message}`)
                    : null}
                </p>
                {loading ? (
                  <div
                    className="loaderDiv"
                    style={{ height: "60px", marginTop: "1em" }}
                  >
                    <PuffLoader size={40} color="#3B6DEA" />
                  </div>
                ) : (
                  <button
                    id="submit"
                    type="submit"
                    style={{ marginTop: "2em" }}
                  >
                    {t("services.submit")}{" "}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="servicesSlider">
        <div className="container-fluid service-container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-lg-5 vertAlign">
              <p id="explore">{t("services.explore")} </p>
            </div>
            <div className="col-lg-7 nopadding">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={2}
                dir={i18n.language == "ar" ? "rtl" : "ltr"}
                key={i18n.language}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1100: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
                spaceBetween={20}
                className="myService"
              >
                <SwiperSlide>
                  <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                    <div className="square serviceSlide">
                      <div>
                        <div className="dark-img">
                          <img src={mobileD} className="img-dark" />

                          <img src={mobile} className="img-light" />
                        </div>{" "}
                        <p id="squareTitle">{t("services.squareTitle")}</p>
                        <p id="squareData">{t("services.squareData")}</p>
                        <Link
                          to="/service"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {" "}
                          <button>
                            {" "}
                            <BsArrowRightShort size={30} />
                          </button>
                        </Link>
                      </div>{" "}
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                    <div className="square serviceSlide">
                      <div>
                        <div className="dark-img">
                          <img src={uiuxD} className="img-dark" />

                          <img src={uiux} className="img-light" />
                        </div>{" "}
                        <p id="squareTitle">{t("services.squareTitle1")}</p>
                        <p id="squareData">{t("services.squareData")}</p>
                        <Link
                          to="/service"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {" "}
                          <button>
                            {" "}
                            <BsArrowRightShort size={30} />
                          </button>
                        </Link>
                      </div>{" "}
                    </div>{" "}
                  </Link>{" "}
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                    <div className="square serviceSlide">
                      <div>
                        <div className="dark-img">
                          <img src={graphicD} className="img-dark" />

                          <img src={graphic} className="img-light" />
                        </div>{" "}
                        <p id="squareTitle">{t("services.squareTitle2")}</p>
                        <p id="squareData">{t("services.squareData")}</p>
                        <Link
                          to="/service"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {" "}
                          <button>
                            {" "}
                            <BsArrowRightShort size={30} />
                          </button>
                        </Link>
                      </div>{" "}
                    </div>{" "}
                  </Link>{" "}
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                    <div className="square serviceSlide">
                      <div>
                        <div className="dark-img">
                          <img src={webD} className="img-dark" />

                          <img src={web} className="img-light" />
                        </div>{" "}
                        <p id="squareTitle">{t("services.squareTitle3")}</p>
                        <p id="squareData">{t("services.squareData")}</p>
                        <Link
                          to="/service"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {" "}
                          <button>
                            {" "}
                            <BsArrowRightShort size={30} />
                          </button>
                        </Link>
                      </div>{" "}
                    </div>{" "}
                  </Link>{" "}
                </SwiperSlide>
              </Swiper>

              <div className="container-fluid servicesMobile">
                <div className="row gy-4 strech">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                      <div className="square service">
                        <div>
                          <div className="dark-img">
                            <img src={mobileD} className="img-dark" />

                            <img src={mobile} className="img-light" />
                          </div>{" "}
                          <p id="squareTitle">{t("services.squareTitle")}</p>
                          <p id="squareData">{t("services.squareData")}</p>
                          <Link
                            to="/service"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {" "}
                            <button>
                              {" "}
                              <BsArrowRightShort size={30} />
                            </button>
                          </Link>
                        </div>{" "}
                      </div>{" "}
                    </Link>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    {" "}
                    <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                      <div className="square service">
                        <div>
                          <div className="dark-img">
                            <img src={uiuxD} className="img-dark" />

                            <img src={uiux} className="img-light" />
                          </div>{" "}
                          <p id="squareTitle">{t("services.squareTitle1")}</p>
                          <p id="squareData">{t("services.squareData")}</p>
                          <Link
                            to="/service"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {" "}
                            <button>
                              {" "}
                              <BsArrowRightShort size={30} />
                            </button>
                          </Link>
                        </div>{" "}
                      </div>{" "}
                    </Link>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                      <div className="square service">
                        <div>
                          <div className="dark-img">
                            <img src={graphicD} className="img-dark" />

                            <img src={graphic} className="img-light" />
                          </div>{" "}
                          <p id="squareTitle">{t("services.squareTitle2")}</p>
                          <p id="squareData">{t("services.squareData")}</p>
                          <Link
                            to="/service"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {" "}
                            <button>
                              {" "}
                              <BsArrowRightShort size={30} />
                            </button>
                          </Link>
                        </div>{" "}
                      </div>{" "}
                    </Link>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    {" "}
                    <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                      <div className="square service">
                        <div>
                          <div className="dark-img">
                            <img src={webD} className="img-dark" />

                            <img src={web} className="img-light" />
                          </div>
                          <p id="squareTitle">{t("services.squareTitle3")}</p>
                          <p id="squareData">{t("services.squareData")}</p>
                          <Link
                            to="/service"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {" "}
                            <button>
                              {" "}
                              <BsArrowRightShort size={30} />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    {" "}
                    <Link to="/service" onClick={() => window.scrollTo(0, 0)}>
                      <div className="square service">
                        <div>
                          <div className="dark-img">
                            <img src={qcD} className="img-dark" />

                            <img src={qc} className="img-light" />
                          </div>{" "}
                          <p id="squareTitle">{t("services.squareTitle4")}</p>
                          <p id="squareData">{t("services.squareData")}</p>
                          <Link
                            to="/service"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {" "}
                            <button>
                              {" "}
                              <BsArrowRightShort size={30} />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ServiceDetails;
