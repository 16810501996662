import "../style/careers.css";

import Footer from "../components/Footer"

import Navbar from "../components/Navbar";
import { BsCircleFill } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { useEffect } from "react";
import proj1  from"../images/proj1.svg"
import proj2  from"../images/proj2.svg"
import proj3  from"../images/proj3.svg"
import proj4  from"../images/proj4.svg"
import proj5  from"../images/proj5.svg"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBlogs } from "../api";
import { generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PuffLoader } from "react-spinners";

function Blogs() {
    const { t,i18n } = useTranslation();
    const [blogs,setBlogs]=useState([])
    const [loading,setLoading]=useState(true)

    let history = useNavigate();

    useEffect(()=>{
        getBlogs().then((response) => {

            setBlogs(response.data.data)
            setLoading(false)
            })
    },[])



    return (

        <div className="aboutUs-container">

            <div id="aboutUsTop">
                <div id="topCover">

                <div>
                    <p id="nice"> {t("blog.projects")}  <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                    <p id="about">{t("blog.our_proud")}  </p>
                </div>
                    <p id="aboutCompany">{t("blog.text")}</p>
                </div>
            </div>
            
            <div className="jobDescribe jobDescribeContainer">
                <div className="container-fluid">
                    {loading?  <div className="loaderDiv" style={{height: "43vh"}}>

                    <PuffLoader size={80} color="#3B6DEA"/>

                    </div>:
                    <div className="row">
                        {blogs && blogs.map((data,index)=>
                            <div style={{marginBottom:"48px"}} className="col-md-4 col-sm-6" key={index}>
                                <div className="proj-width">
                                    <Link to={`/blogs/${data.id}`}>
                                        <div className="projectDiv">
                                            <div className="projImg">
                                                <div  style={{backgroundImage:`url(${data.image})`}}></div>
                                            </div>
                                            <div className="projData"> 
                                                <div className="projType" id="type1">
                                                    <BsCircleFill size={7} color=" #7A5AF8"/><p> {data.date}</p>
                                                </div>
                                                <p id="projName">{data.title}</p>
                                                <p id="projDetail">{data.description}</p>
                                            </div>
                                            <button id="viewProj">{t("blog.details")}  <BsArrowRightShort size={27}/></button>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>}
                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default Blogs