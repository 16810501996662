import logo from "../images/logo.svg";
import { BiChevronDown } from "react-icons/bi";
import { NavLink, Link } from "react-router-dom";
import "../style/home.css";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import settings from "../images/settings.svg";
import { BsGlobe2 } from "react-icons/bs";
import { NavDropdown } from 'react-bootstrap';

function Navbar(props) {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);
  const [setting, setSetting] = useState(false);
  const [language, setLanguage] = useState(false);

  const [lang, setLang] = useState("ar");
  const { t, i18n } = useTranslation();
  const [header, setHeader] = useState("");

  const listenScrollEvent = (event) => {
    if (window.scrollY > 10) {
      setHeader("changeBakground");
    } else {
      setHeader("");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  function changeLanguage(language) {
    setSetting(false);
    setLanguage(false);

    i18n.changeLanguage(language);
    localStorage.setItem("language", JSON.stringify(language));
    setLang(language);

    if (language === "en") {
      document.getElementById("root").style.direction = "ltr";
    } else {
      document.getElementById("root").style.direction = "rtl";
    }
    setToggle(false);
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("language"));
    i18n.changeLanguage(data);
    setLang(data);

    if (i18n.language === "en") {
      document.getElementById("root").style.direction = "ltr";
    } else if (i18n.language === "ar") {
      document.getElementById("root").style.direction = "rtl";
    } else {
      document.getElementById("root").style.direction = "rtl";
    }

    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = " بدايات للتكنولوجيا";
    } else {
      document.title = "BDAIAT TECHNOLOGIES";
    }
  }, [lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const history = useNavigate();

  const handleGoToQuotationByNav = () =>{
    localStorage.setItem("source", 'navbarBtn')
      history('/quotation')
  }

  const [dropdownActive, setDropdownActive] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownActive(!dropdownActive);
  };

  return (
    <div className={header}>
      <div className="topNavbar">
        {/*
  <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${logo})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>*/}

        <Link to="/">
          <div
            className="logo"
            style={{ backgroundImage: `url(${logo})` }}
          ></div>
        </Link>
        <div className="links">
          <NavLink to="/">
            <p>{t("navbar.home")}</p>
          </NavLink>
          <NavLink to="/about-us">
            <p>{t("navbar.about")}</p>
          </NavLink>
          <NavLink to="/process">
            <p>{t("navbar.process")}</p>
          </NavLink>
          {/* <NavLink to="/team"><p>{t("navbar.team")}</p></NavLink> */}

          <HashLink className="anchor" to="/#services">
            <p>{t("navbar.services")}</p>
          </HashLink>
          {/* <NavLink  to="/products"><p>{t("navbar.products")}</p></NavLink> */}
          
          {/* <NavDropdown 
            title={t("navbar.products")} 
            id="nav-dropdown"
            show={dropdownActive}
            onToggle={handleDropdownToggle} 
            className={dropdownActive ? 'active-dropdown' : ''}
          >
            <NavDropdown.Item as={NavLink} to="/products" activeClassName="active">
              <p className="besallaNav">Besalla</p>
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/kites" activeClassName="active">
              <p className="kitesNav">Kites Keepers</p>
            </NavDropdown.Item>
          </NavDropdown> */}

          {/* <NavLink  to="/kites"><p>{t("navbar.kites")}</p></NavLink> */}

          {/* <p>{t("navbar.clients")}</p>
<p>{t("navbar.projects")}</p>
<p>{t("navbar.careers")}</p> */}
          {/* <HashLink className="anchor" to="/#clients"><p>{t("navbar.clients")}</p></HashLink>
<NavLink to="/projects"><p>{t("navbar.projects")}</p></NavLink>
<NavLink to="/career"><a href="#careers"><p>{t("navbar.careers")}</p></a></NavLink> */}
        </div>

        <div>
          <button className="toggleBtn" onClick={() => setToggle(!toggle)}>
            <HiOutlineMenuAlt2 />
          </button>
          <div
            id="reqQuotation"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <NavLink to="/quotation">
              <button className="request">{t("navbar.quotation")}</button>{" "}
            </NavLink> */}

            <button onClick={() => handleGoToQuotationByNav()} className="request">{t("navbar.quotation")}</button>{" "}

            <div style={{ position: "relative" }}>
              {setting ? (
                <div className="langauge-modal">
                  <button
                    id="lightBtn"
                    onClick={() => {
                      props.setMode("lightMode");
                      localStorage.setItem("mode", "lightMode");
                      setSetting(false);
                    }}
                  >
                    {t("navbar.light")}
                  </button>

                  <button
                    onClick={() => {
                      props.setMode("darkMode");
                      localStorage.setItem("mode", "darkMode");
                      setSetting(false);
                    }}
                  >
                    {t("navbar.dark")}
                  </button>
                </div>
              ) : null}
              {/* <button onClick={()=>{setSetting(!setting); setLanguage(false);}} style={i18n.language=="ar"?{padding:"8px",marginRight:"1em"}:{padding:"8px",marginLeft:"1em"}} className="request"><img src={settings}/></button> */}
            </div>

            {/* <button className="request" style={{ fontFamily : "cairo" }} onClick={() => changeLanguage("en")}>English</button>
            <button className="request" style={{ fontFamily : "cairo" }} onClick={() => changeLanguage("ar")}>العربية</button> */}

            <button className="request" style={{ fontFamily : "cairo" }} onClick={() => changeLanguage(lang === "ar" ? "en" : "ar")}>
              {lang === "ar" ? "English" : "العربية"}
            </button>

            {/* <div style={{ position: "relative" }}>
              {language ? (
                <div className="langauge-modal ">
                  <button
                    id="lightBtn"
                    style={{ fontFamily: "cairo" }}
                    onClick={() => changeLanguage("ar")}
                  >
                    العربية
                  </button>

                  <button onClick={() => changeLanguage("en")}>English</button>
                </div>
              ) : null}
              <button
                onClick={() => {
                  setLanguage(!language);
                  setSetting(false);
                }}
                style={
                  i18n.language == "ar"
                    ? { padding: "8px", marginRight: "1em" }
                    : { padding: "8px", marginLeft: "1em" }
                }
                className="request"
              >
                <BsGlobe2 size={24} />
              </button>
            </div> */}
          </div>{" "}
        </div>
      </div>
      <div id="navbarMargin"></div>

      <div
        className={toggle ? "mobileLinks toggOpen" : "mobileLinks toggClose"}
      >
        <NavLink to="/" onClick={() => setToggle(false)}>
          <p>{t("navbar.home")}</p>
        </NavLink>
        <NavLink to="/about-us" onClick={() => setToggle(false)}>
          <p>{t("navbar.about")}</p>
        </NavLink>
        <NavLink to="/process" onClick={() => setToggle(false)}>
          <p>{t("navbar.process")}</p>
        </NavLink>
        <HashLink
          className="anchor"
          to="/#services"
          onClick={() => setToggle(false)}
        >
          <p>{t("navbar.services")}</p>
        </HashLink>
        {/* <NavLink to="/products" onClick={()=>setToggle(false)}><p>{t("navbar.products")}</p></NavLink>
<HashLink className="anchor" to="/#clients" onClick={()=>setToggle(false)}><p>{t("navbar.clients")}</p></HashLink>
<NavLink to="/projects" onClick={()=>setToggle(false)}><p>{t("navbar.projects")}</p></NavLink>
<NavLink to="/career" onClick={()=>setToggle(false)}><a href="#careers"><p>{t("navbar.careers")}</p></a></NavLink> */}

        {/* <NavLink to="/team" onClick={()=>setToggle(false)}><p>{t("navbar.team")}</p></NavLink> */}

        <div>
          <select
            value={lang}
            onChange={(e) => changeLanguage(e.target.value)}
            style={{ margin: "0 1em", border: "none" }}
          >
            <option value={"en"}> English </option>
            <option value={"ar"}> العربية </option>
          </select>

          {/* <select onChange={(e)=>{props.setMode(e.target.value);localStorage.setItem("mode",e.target.value); setToggle(false)}} style={{margin:"0 1em",border:"none"}}>
    <option value={"lightMode"}> {t("navbar.light")} </option>
    <option value={"darkMode"}> {t("navbar.dark")} </option>
  </select> */}
          <NavLink to="/quotation" onClick={() => setToggle(false)}>
            <button className="request">{t("navbar.quotation")}</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
