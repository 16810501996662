import Footer from "../components/Footer";
import "../style/kites.css";
import Navbar from "../components/Navbar";
import iosStore from "../images/iosStore.png";
import appStore from "../images/appStore.png";

import MessengerCustomerChat from "react-messenger-customer-chat";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Helmet } from "react-helmet-async";
import { Button, Modal } from 'react-bootstrap';

function Kites() {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = "كايتس كيبر";
    } else {
      document.title = "KITES KEEPER";
    }
  }, []);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

    return (
        <div className="products-container2" style={{direction : currentLanguage === 'en' ? 'rtl': 'ltr'}}>
            <Helmet>
            <title>Bdaiat Technologies - KITES KEEPER</title>
            <link rel="canonical" href="https://kiteskeeper.com/" />

            <script type="application/ld+json">{`
                {"@context":"http://schema.org","@type":"Organization","name":"Bdaiat Technolgoies","@id":"BdaiatTechnologies","url":"https://www.bdaiat.com/","logo":"https://www.bdaiat.com/bdaiat-logo.png","address":{"@type":"PostalAddress","addressLocality":"Sidi Gaber","addressRegion":"Alexandria","postalCode":"21523","streetAddress":"Building #378 Horya Road, Sidi Gaber, Alexandria, Egypt","addressCountry":"Egypt"},"email":"mailto:info@bdaiat.com","contactPoint":[{"@type":"ContactPoint","telephone":"+201097101006","contactType":"Technical Support"}],"sameAs":["https://www.facebook.com/bdaiat","https://www.instagram.com/bdaiat/","https://twitter.com/BdaiatTech","https://www.linkedin.com/company/bdaiat"]}
            `}</script>
            </Helmet>
            <div style={{ position: "relative" }}>
            <div className="BdaitBluLogo"></div>
            </div>

            <div className="container-fluid kites">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaImgsCol">
                        <div className={`bsImgOne ${currentLanguage === 'ar' ? 'arabicStyleBsImgOne' : ''}`}>

                        </div>
                        <div className={`bsImgTwo ${currentLanguage === 'ar' ? 'arabicStyleBsImgTwo' : ''}`}>

                        </div>
                    </div>
                    <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaTextCol ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextCol' : ''}`}>
                        <div className={`besallaLogo ${currentLanguage === 'ar' ? 'arabicStyleBesallaLogo' : ''}`}>

                        </div>
                        <div className="besallaText">
                            <h1>{t("kites.title2")}</h1>
                            <p className={`besallaTextP ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextP' : ''}`}>{t("kites.title")}</p>
                            {/* <p className="besallaTextP2">{t("products.title3")}</p> */}
                            <Button variant="primary" onClick={handleShow} className={`besallaTextBtn ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextBtn' : ''}`}>
                                {t("kites.bookBtn")}
                            </Button><input className={`besallaTextInput ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextInput' : ''}`} type="email" placeholder={t("kites.placeholder")}/>

                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <iframe 
                                    width="100%" 
                                    height="450" 
                                    src="https://crm.bdaiat.com/lead-web-form" 
                                    frameBorder="0" 
                                    allowFullScreen
                                    ></iframe>
                                </Modal.Body>
                            </Modal>
                            {/* <button className={`besallaTextBtn ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextBtn' : ''}`}>{t("kites.bookBtn")}</button><input className={`besallaTextInput ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextInput' : ''}`} type="email" placeholder={t("kites.placeholder")}/> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid aboutKites">
                <div className={`row kitesRow ${currentLanguage === 'ar' ? 'arabicStyleKitesRow' : ''}`}>
                    <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 besallaTextCol">
                        <div className="besallaText">
                            <h1 className={`besallaTextH1 ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextH1' : ''}`}>{t("kites.title4")}</h1>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 besallaImgCol">
                        <div className="besallaLogo"></div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol1 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol1' : ''}`}>
                        <div>
                            <div className="bsAboutImg1"></div>
                            <div className="bsAboutHeader1"><h1 className={`bsAboutHeader1H1 ${currentLanguage === 'ar' ? 'arabicStyleBsAboutHeader1H1' : ''}`}>{t("kites.about2")}</h1></div>
                            <div className="bsAboutP1"><p className={`bsAboutP1P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP1P' : ''}`}>{t("kites.text2")}</p></div>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol2 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol2' : ''}`}>
                        <div>
                            <div className="bsAboutImg2"></div>
                            <div className="bsAboutHeader2"><h1 className={`bsAboutHeader2H1 ${currentLanguage === 'ar' ? 'arabicStyleBsAboutHeader2H1' : ''}`}>{t("kites.about1")}</h1></div>
                            <div className="bsAboutP2"><p className={`bsAboutP2P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP2P' : ''}`}>{t("kites.text1")}</p></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol3 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol3' : ''}`}>
                        <div>
                            <div className="bsAboutImg3"></div>
                            <div className="bsAboutHeader3"><h1 className={`bsAboutHeader3H1 ${currentLanguage === 'ar' ? 'arabicStyleBsAboutHeader3H1' : ''}`}>{t("kites.about4")}</h1></div>
                            <div className="bsAboutP3"><p className={`bsAboutP3P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP3P' : ''}`}>{t("kites.text4")}</p></div>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol4 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol4' : ''}`}>
                        <div>
                            <div className="bsAboutImg4"></div>
                            <div className="bsAboutHeader4"><h1 className={`bsAboutHeader4H1 ${currentLanguage === 'ar' ? 'arabicStyleBsAboutHeader4H1' : ''}`}>{t("kites.about3")}</h1></div>
                            <div className="bsAboutP4"><p className={`bsAboutP4P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP4P' : ''}`}>{t("kites.text3")}</p></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutColRes ${currentLanguage === 'ar' ? 'arabicStyleAboutColRes' : ''}`}>
                        <div className={`aboutCol5 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol5' : ''}`}>
                            <div className="bsAboutImg5"></div>
                            <div className="bsAboutHeader5"><h1 className={`bsAboutHeader5H1 ${currentLanguage === 'ar' ? 'arabicStyleBsAboutHeader5H1' : ''}`}>{t("kites.about5")}</h1></div>
                            <div className="bsAboutP5"><p className={`bsAboutP5P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP5P' : ''}`} style={{ color: "#ffffff" }}>{t("kites.text5")}</p></div>
                            <div className="bsAboutP7">
                                <p className={`bsAboutP7P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP7P' : ''}`} style={{ color: "#ffffff" }}>
                                    <Button variant="primary" onClick={handleShow} className={`bsAboutP7Btn ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP7Btn' : ''}`}>
                                    {t("kites.contactBtn")}
                                    </Button>
                                    {t("kites.text7")}
                                </p> 

                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <iframe 
                                        width="100%" 
                                        height="450" 
                                        src="https://crm.bdaiat.com/lead-web-form" 
                                        frameBorder="0" 
                                        allowFullScreen
                                        ></iframe>
                                    </Modal.Body>
                                </Modal>
                                {/* <p className={`bsAboutP7P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP7P' : ''}`} style={{ color: "#ffffff" }}><button className={`bsAboutP7Btn ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP7Btn' : ''}`}>{t("kites.contactBtn")}</button>{t("kites.text7")}</p> */}
                            </div>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol6 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol6' : ''}`}>
                        <div>
                            <div className="bsAboutImg6"></div>
                            <div className="bsAboutHeader6"><h1 className={`bsAboutHeader6H1 ${currentLanguage === 'ar' ? 'arabicStyleBsAboutHeader6H1' : ''}`}>{t("kites.about6")}</h1></div>
                            <div className="bsAboutP6"><p className={`bsAboutP6P ${currentLanguage === 'ar' ? 'arabicStyleBsAboutP6P' : ''}`}>{t("kites.text6")}</p></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid kitesEnd">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaImgsCol">
                        <div className="bsImgTwo">

                        </div>
                    </div>
                    <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaTextCol ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextCol2' : ''}`}>
                        <div className="besallaText">
                            <h1>{t("kites.about7")}</h1>
                            {/* <p>{t("kites.about8")}</p> */}
                            <div className="store">
                                <a href="http://bit.ly/2SU7y8N" target="_blank"><img className="appStoreLogo" src={appStore}/></a>
                                <a href="https://apple.co/2ZeWV2s" target="_blank"><img className="AppleStore" src={iosStore}/></a>
                            </div>
                            {/* <div className="besallaLogo"></div>
                            <div className="WhatsappYellowLogo"></div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>

    );
}

export default Kites;
