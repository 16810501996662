   

 
import React, {useEffect, useState,useRef} from 'react';
import { applyAsFreelancer,applyForJob } from '../api';
import { useTranslation } from 'react-i18next';
import {BsUpload} from "react-icons/bs";
import {useFormik} from "formik";
import * as yup from "yup"
import { PuffLoader } from 'react-spinners';
function Modal(props) {
const [loading,setLoading]=useState(false)
const { t, i18n } = useTranslation();
const [err,setErr]=useState(false)

let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const imgRef= useRef()
const Schema= props.type=="freelance"?yup.object().shape({
  name: yup.string().required(("validation.name")),
  phone: yup.string().required(("validation.phone")).min(10,("validation.phoneInvalid")),
  email: yup.string().required(("validation.email")).matches(emailRegx,("validation.emailInvalid")),
  job: yup.string().required(("validation.jobReq")),

  }):yup.object().shape({
    name: yup.string().required(("validation.name")),
    phone: yup.string().required(("validation.phone")).min(10,("validation.phoneInvalid")),
    email: yup.string().required(("validation.email")).matches(emailRegx,("validation.emailInvalid")),
  
    })


  
    let initialValues = {};

if (props.type === 'freelance') {
  initialValues = {
    name: '',
    email: '',
    phone: '',
    job: ''
  };
}  else {
  // set default initial values
  initialValues = {
    name: '',
    email: '',
    phone: ''
  };
}

const formik = useFormik({
  initialValues: initialValues,
  validationSchema: Schema,
  onSubmit,
});


console.log(formik.errors)
function onSubmit(){

  if(imgRef.current.files[0]==undefined){
    setErr(true)
  }else{
  setLoading(true)
  setErr(false)

  const formData = new FormData()

formData.append('name',formik.values.name)
formData.append('email',formik.values.email)
formData.append('phone',formik.values.phone)
formData.append('type',"employee")
{props.type=="freelance"?

formData.append('job_title',formik.values.job):
formData.append('job_id',parseInt(props.id))

}

formData.append('attach_file',imgRef.current.files[0])
if(props.type=="freelance"){
applyAsFreelancer(formData,i18n.language).then((response) => {
  props.setSuccess(true)

  props.setModal(false)

}

).catch((err)=>{
  props.setModal(false)

})
}
else{
  applyForJob
  (formData,i18n.language).then((response) => {
    props.setSuccess(true)
  
    props.setModal(false)
  
  }
  
  )
}
  }
}

    return ( 
      
 
  
        <div  className="foodModal">
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content logoutModal" style={{height:"auto",width:"50vw ", maxWidth:"400px",minWidth:"337px"}}>
          
            <div >
             <div className='modalBody'>
        
          <form className="serviceForm" onSubmit={formik.handleSubmit}> 
          {props.type=="freelance"?
<p id="jobName" style={{fontSize:"30px"}}>{t("team.freeLance")}</p>:
<p id="jobName" style={{fontSize:"25px"}}>{props.name}</p>}
<label>{t("services.name")} </label>
<input type="text" placeholder={t("services.first")}  value={formik.values.name} id="name" name="name" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"error":null}/>
<p className="errMsg">{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</p>


<label>{t("quotation.phone")}</label>
<input type="text" placeholder="+1 (555) 000-0000"   value={formik.values.phone} id="phone" name="phone" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"error":null}/>
<p className="errMsg">{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</p>

<label>{t("services.email")} </label>
<input type="text" placeholder="you@company.com"   value={formik.values.email} id="email" name="email" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email?"error":null}/>
<p className="errMsg">{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</p>
{props.type=="freelance"?
<>
<label>{t("validation.job")} </label>
<input type="text" placeholder={t("validation.job")}   value={formik.values.job} id="job" name="job" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.job && formik.touched.job?"error":null}/>
<p className="errMsg">{formik.errors.job && formik.touched.job?t(`${formik.errors.job}`):null}</p>
</>:null}
<label>  {t("team.attach")}</label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input type="file" ref={imgRef} multiple="multiple" accept=".pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword" className={err?'error':null}/>
<BsUpload size={20} color="#ACACAC"/>

</div>
<p className="errMsg">{err?i18n.language=="en"? "Attachement is required":"برجاء إرفاق ملف": null}</p>




{loading?  <div className="loaderDiv" style={{height: "60px",marginTop:"1em"}}>

<PuffLoader size={40} color="#3B6DEA"/>

</div>:

<button id="submit" type="submit" style={{marginTop:"2em"}}>{t("team.apply")} </button>}
</form>
           
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default Modal;
 