import "../style/careers.css";

import Footer from "../components/Footer"

import Navbar from "../components/Navbar";
import { BsCircleFill } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { useEffect } from "react";
import proj1  from"../images/proj1.svg"
import proj2  from"../images/proj2.svg"
import proj3  from"../images/proj3.svg"
import proj4  from"../images/proj4.svg"
import proj5  from"../images/proj5.svg"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProjects } from "../api";
import { generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PuffLoader } from "react-spinners";

function Projects() {
    const { t,i18n } = useTranslation();
    const [projects,setProjects]=useState([])
    const [loading,setLoading]=useState(true)

    let history = useNavigate();

    useEffect(()=>{
        getProjects().then((response) => {

            setProjects(response.data.data)
            setLoading(false)
            }

            )
        },[])

       

return(

 

    <div className="aboutUs-container">

<div id="aboutUsTop">
<div id="topCover">

<div>
<p id="nice"> {t("project.projects")}  <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
<p id="about">{t("project.our_proud")}  </p>
</div>
<p id="aboutCompany">{t("services.text")}</p>
</div></div>
 
<div className="jobDescribe jobDescribeContainer">
<div className="container-fluid">
{loading?  <div className="loaderDiv" style={{height: "43vh"}}>

<PuffLoader size={80} color="#3B6DEA"/>

</div>:
<div className="row  " >

{projects && projects.map((data,index)=>

<div style={{marginBottom:"48px"}} className="col-md-4 col-sm-6" key={index}>
    
    <div className="proj-width">

    <Link to={`/projects/${data.id}`}>
<div className="projectDiv">
<div className="projImg">
    <div  style={{backgroundImage:`url(${data.pic})`}}></div>

</div>
<div className="projData"> 
<div className="projType" id="type1">
<BsCircleFill size={7} color=" #7A5AF8"/><p> {data.project_service}</p></div>
<p id="projName">{data.name}</p>
<p id="projDetail">{data.description}</p>
</div>

<button id="viewProj">{t("project.details")}  <BsArrowRightShort size={27}/></button>

</div></Link></div>

    </div>
)}



   

    </div>}
    </div>
</div>

<Footer/>
    </div>
)
}

export default Projects