   

 
import React, {useEffect, useState,useRef} from 'react';
import { userFeedback } from '../api';
import { useTranslation } from 'react-i18next';
import {BsUpload} from "react-icons/bs";
import {useFormik} from "formik";
import * as yup from "yup"
import {BsFillTrashFill}  from 'react-icons/bs';
import logo from "../images/logo.svg"
import { FiUploadCloud } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import Success from '../components/Success';
function Feedback() {

const { t, i18n } = useTranslation();
const [loading,setLoading]=useState(false)
const[errMsg,setErrMsg]=useState()
const[err,setErr]=useState(false)
const uploadImg= useRef()
const proJectNameRef= useRef()
const [dragging, setDragging] = useState(false);
const [image,setImage]=useState()
const [preview,setPreview]=useState()
const [success,setSuccess]=useState(false)

const Schema= yup.object().shape({
  name: yup.string().required(("validation.name")),
  job_title: yup.string().required(("validation.jobReq")),
  description: yup.string().required(("validation.message")),

  })

  const formik = useFormik({
    initialValues:{
    name: '',
    job_title: '',
    description: '',

     
    },
    validationSchema: Schema,
    onSubmit,
    });

function onSubmit(){
  setLoading(true)
  const formData = new FormData()
  formData.append('name',formik.values.name)
  if(preview){
  formData.append('client_img',preview)}
  if(proJectNameRef.current.value){
    formData.append('project_name',proJectNameRef.current.value)}
    formData.append('job_title',formik.values.job_title)
    formData.append('description',formik.values.description)


userFeedback(formData,i18n.language).then((response) => {
  setSuccess(true)
  setLoading(false)
var frm = document.getElementsByName('feedBackFrm')[0];
frm.reset(); 
formik.handleReset()
setPreview(null)
setImage(null)
}

).catch((err)=>{
  setLoading(false)
})
}

useEffect(() => {
console.log(preview)
  if (preview) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      setErr(false)

    };
    reader.readAsDataURL(preview);
  } 

}, [preview]);

const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
   const imageType = /^image\//;

  if (files.length === 1 && imageType.test(files[0].type)) {
    setPreview(files[0]);
  } else {
    setErr(true)
    setErrMsg("Please drop only one image file");
  }

  };


    return ( 
      
 
  
        <div  className='feedback-container' >
    {success?<Success type="feedback" setModal={setSuccess}/>:null}

    <div id="feedbackHeader">
        <Link to="/">
        <div className="logo" style={{backgroundImage:`url(${logo})`}}>
</div>
        </Link>
   
    </div>

            
             <div className='modalBody  container' style={{marginBottom:"3em",maxWidth:"952px"}}>
        
          <form name="feedBackFrm" className="serviceForm feedBackForm" onSubmit={formik.handleSubmit}> 
<p id="tryFeedback" >{t("feedback.title")}</p>
<p id="tryText" >{t("feedback.title2")}</p>

<label>{t("services.name")} </label>
<input type="text" placeholder={t("services.first")}  value={formik.values.name} id="name" name="name" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"error":null}/>
<p className="errMsg">{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</p>


<label>{t("feedback.projName")}</label>
<input type="text" placeholder={t("feedback.your")}  ref={proJectNameRef}/>

<label>{t("validation.job")}</label>
<input type="text" placeholder={t("validation.job")}   value={formik.values.job_title} id="job_title" name="job_title" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.job_title && formik.touched.job_title?"error":null}/>
<p className="errMsg">{formik.errors.job_title && formik.touched.job_title?t(`${formik.errors.job_title}`):null}</p>


<label>  {t("feedback.feed")}</label>
<textarea type="text" placeholder={t("feedback.enter")}  value={formik.values.description} id="description" name="description" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.description && formik.touched.description?"error":null}/>
<p className="errMsg">{formik.errors.description && formik.touched.description?t(`${formik.errors.description}`):null}</p>


<label>  {t("feedback.attach")}</label>
<div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>{t("feedback.click")}</span> {t("feedback.drag")}<br/> SVG, PNG, JPG {t("feedback.or")} GIF</p>
</>}
</div>

{err?
  <p className="errMsg" style={{textAlign:"center",marginBottom:"0",marginTop:"1em"}}>{errMsg}</p>:null}
  {loading?  <div className="loaderDiv" style={{height: "60px",marginTop:"1em"}}>
  
  <PuffLoader size={40} color="#3B6DEA"/>
  
  </div>:
<button id="submit" style={{marginTop:"2em"}}>{t("services.submit")} </button>}
</form>
           
            </div>
           
     
            </div>
          

   
    

    );
}

export default Feedback;
 