import "../style/process.css";
import Footer from "../components/Footer";

import process1 from "../images/process1.png";
import process2 from "../images/process2.png";
import process3 from "../images/process3.png";
import process4 from "../images/spaceShip.png";
import processNum1 from "../images/processNum1.png";
import processNum2 from "../images/processNum2.png";
import processNum3 from "../images/processNum3.png";
import bdaiatWhiteLogo from "../images/bdaiatWLogo.png";

import { PuffLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import { getClients } from "../api";

import { Swiper, SwiperSlide } from "swiper/react";
import Navbar from "../components/Navbar";
import { TbArrowBarToUp } from "react-icons/tb";
import { BsCircleFill } from "react-icons/bs";
import Feedback from "../components/Feedback";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useInView } from "react-intersection-observer";
import { RiDoubleQuotesL } from "react-icons/ri";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import required modules
import { Navigation } from "swiper";
import smile from "../images/smile.svg";
import { getFeedback, getProjects } from "../api";
import { RxDoubleArrowRight } from "react-icons/rx";
import { useState, useRef } from "react";
import Success from "../components/Success";
import { Helmet } from "react-helmet-async";

function Process() {
  const [up, setUp] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const { ref: teamRef, inView: myElementIsVisible } = useInView();
  const { ref: topRef, inView: topVisible } = useInView();

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const translateY = scrollPosition * 0.5; // Adjust the multiplier as needed

  useEffect(() => {
    setLoading(false);

    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = "كيف نعمل؟";
    } else {
      document.title = "PROCESS";
    }
  }, []);

  window.onscroll = function (e) {
    if (myElementIsVisible) {
      setUp(true);
    } else if (!myElementIsVisible && topVisible) {
      setUp(false);
    }
  };

  $(document).ready(function () {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();

      // Check each row's position and apply the 'hide' class accordingly
      $(".row").each(function () {
        var rowOffset = $(this).offset().top;

        if (rowOffset < scroll + windowHeight * 0.75) {
          $(this).removeClass("hide");
        } else {
          $(this).addClass("hide");
        }
      });
    });
  });

  const history = useNavigate();

  const handleGoToQuotationByProcessKickOff = () => {
    localStorage.setItem("source", "ProcessKickOffBtn");
    history("/quotation");
  };

  return (
    <div className="changeBckg home-container" style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Bdaiat Technologies - Process</title>
        <link rel="canonical" href="https://www.bdaiat.com/process" />

        <script type="application/ld+json">{`
          {"@context":"http://schema.org","@type":"Organization","name":"Bdaiat Technolgoies","@id":"BdaiatTechnologies","url":"https://www.bdaiat.com/","logo":"https://www.bdaiat.com/bdaiat-logo.png","address":{"@type":"PostalAddress","addressLocality":"Sidi Gaber","addressRegion":"Alexandria","postalCode":"21523","streetAddress":"Building #378 Horya Road, Sidi Gaber, Alexandria, Egypt","addressCountry":"Egypt"},"email":"mailto:info@bdaiat.com","contactPoint":[{"@type":"ContactPoint","telephone":"+201097101006","contactType":"Technical Support"}],"sameAs":["https://www.facebook.com/bdaiat","https://www.instagram.com/bdaiat/","https://twitter.com/BdaiatTech","https://www.linkedin.com/company/bdaiat"]}
      `}</script>
      </Helmet>
      {success ? <Success type="job" setModal={setSuccess} /> : null}
      {modal ? (
        <Modal setSuccess={setSuccess} type="freelance" setModal={setModal} />
      ) : null}

      {loading ? (
        <div className="loaderDiv" style={{ height: "50vh" }}>
          <PuffLoader size={80} color="#3B6DEA" />
        </div>
      ) : (
        <>
          <button
            id="toTop"
            className={up ? "showUp" : "hideUp"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <TbArrowBarToUp size={40} />
          </button>

          <div
            className={`drkImg ${
              currentLanguage === "ar"
                ? "processFlib-background"
                : "process-background"
            }`}
            style={{
              height: "750px",
              transform: `translate3d(0, ${translateY}px, 0)`,
            }}
          >
            <div className="drkImgContent">
              <div className="firstDiv marginTopFirst container-fluid">
                <div className="row ">
                  <div className="col-lg-7  alignVert">
                    <div>
                      <svg
                        className="blackBck"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1124"
                        height="928"
                        viewBox="0 0 1124 928"
                        fill="none"
                      >
                        <path
                          d="M281.182 0H0V928H28.4096H79.4012H160.259H332.174L662.891 923.598H724.809H794.558H904.372H1124L1016.19 801.821L891.624 670.508L669.447 424.753L281.182 0Z"
                          fill="#010F16"
                        />
                      </svg>

                      <div style={{ zIndex: "1", position: "relative" }}>
                        <p id="turnIdeas">
                          {t("processPage.title")} <br />{" "}
                          {t("processPage.title2")} {t("processPage.title3")}{" "}
                          {t("processPage.title4")}{" "}
                        </p>
                        <p id="powerful2">{t("processPage.text")} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fourthDiv container-fluid" id="process">
            <div className="row analysisRow">
              <div className="col-lg-2 col2Responsive">
                <div
                  className={`analNum ${
                    currentLanguage === "ar" ? "arabicStyleAnalNum" : ""
                  }`}
                >
                  <h1
                    className={`${
                      currentLanguage === "ar" ? "arabicStyleAnalNumH1" : ""
                    }`}
                  >
                    {t("processPage.rowNum1")}
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 col6Responsive">
                <div className="analText">
                  <p
                    className={`processTitle ${
                      currentLanguage === "ar" ? "arabicStyleAnalTextTitle" : ""
                    }`}
                  >
                    {t("processPage.analysis")}
                  </p>
                  <p
                    className={`processDesc ${
                      currentLanguage === "ar" ? "arabicStyleAnalTextDesc" : ""
                    }`}
                  >
                    {t("processPage.analysisDesc")}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col4Responsive">
                <div className="analImg">
                  <img src={process1} />
                </div>
              </div>
            </div>

            <div className="row designRow">
              <div className="col-lg-2 col2Responsive">
                <div
                  className={`designNum ${
                    currentLanguage === "ar" ? "arabicStyleDesignNum" : ""
                  }`}
                >
                  <h1
                    className={`${
                      currentLanguage === "ar" ? "arabicStyleDesignNumH1" : ""
                    }`}
                  >
                    {t("processPage.rowNum2")}
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 col6Responsive">
                <div className="designText">
                  <p
                    className={`processTitle ${
                      currentLanguage === "ar"
                        ? "arabicStyleDesignTextTitle"
                        : ""
                    }`}
                  >
                    {t("processPage.desgin")}
                  </p>
                  <p
                    className={`processDesc ${
                      currentLanguage === "ar"
                        ? "arabicStyleDesignTextDesc"
                        : ""
                    }`}
                  >
                    {t("processPage.designDesc")}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col4Responsive">
                <div
                  className={`designImg ${
                    currentLanguage === "ar" ? "arabicStyleDesignImg" : ""
                  }`}
                >
                  <img src={process2} />
                </div>
              </div>
            </div>

            <div className="row developRow">
              <div className="col-lg-2 col2Responsive">
                <div
                  className={`developNum ${
                    currentLanguage === "ar" ? "arabicStyleDevelopNum" : ""
                  }`}
                >
                  <h1
                    className={`${
                      currentLanguage === "ar" ? "arabicStyleDevelopNumH1" : ""
                    }`}
                  >
                    {t("processPage.rowNum3")}
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 col6Responsive">
                <div className="developText">
                  <p
                    className={`processTitle ${
                      currentLanguage === "ar"
                        ? "arabicStyleDevelopTextTitle"
                        : ""
                    }`}
                  >
                    {t("processPage.development")}
                  </p>
                  <p
                    className={`processDesc ${
                      currentLanguage === "ar"
                        ? "arabicStyleDevelopTextDesc"
                        : ""
                    }`}
                  >
                    {t("processPage.developmentDesc")}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col4Responsive">
                <div
                  className={`developImg ${
                    currentLanguage === "ar" ? "processFlib-background3" : ""
                  }`}
                >
                  {/* <img src={process3} /> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`container-fluid process4 ${
              currentLanguage === "ar" ? "processFlib-background2" : ""
            }`}
          >
            <div className="row ">
              <div
                className={`col-lg-7 alignVert2 ${
                  currentLanguage === "ar" ? "alignVertArabicStyle" : ""
                }`}
              >
                <div>
                  <div style={{ zIndex: "1", position: "relative" }}>
                    <p id="turnIdeas">{t("processPage.ready")}</p>
                    <p id="powerful2">{t("processPage.ready2")} </p>
                  </div>
                  <div className="btnsDiv">
                    {/* <Link to="/quotation">
                              <button className={`talk ${currentLanguage === 'ar' ? 'arabicStyleTalk' : ''}`}> 
                                {t("processPage.talkBtn")}  <img className="btnlogo" src={bdaiatWhiteLogo} />
                              </button>{" "}
                        </Link> */}
                    <button
                      onClick={() => handleGoToQuotationByProcessKickOff()}
                      className={`talk ${
                        currentLanguage === "ar" ? "arabicStyleTalk" : ""
                      }`}
                    >
                      {t("processPage.talkBtn")}{" "}
                      <img className="btnlogo" src={bdaiatWhiteLogo} />
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col5Responsive">
                <div className="processImgLogo">
                  <div
                    className={`btnlogo ${
                      currentLanguage === "ar" ? "arabicStyleBdaiatLogoWH" : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default Process;
