import "../style/careers.css";

import Footer from "../components/Footer";

import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { HiArrowUpRight } from "react-icons/hi2";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiDollarCircle } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import { getCategories, getCareerByCategory, getAllCareers } from "../api";
function Career() {
  const [active, setActive] = useState(0);
  const [loadCareers, setLoadCareers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(true);
  const [careers, setCareers] = useState(true);
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(JSON.parse(localStorage.getItem("language"))==="ar"){
      document.title = "الوظائف";
    }else {
        document.title = 'CAREER';
    }
    
    getCategories().then((response) => {
      setCategories(response.data.data);
      getAllCareers().then((response) => {
        setCareers(response.data.data);

        setLoading(false);
      });
    });
  }, []);

  function handleActive(index, id) {
    setLoadCareers(true);
    setActive(index);
    if (index == 0) {
      getAllCareers()
        .then((response) => {
          setCareers(response.data.data);

          setLoadCareers(false);
        })
        .catch((err) => {
          setLoadCareers(false);
        });
    } else {
      getCareerByCategory(id)
        .then((response) => {
          setCareers(response.data.data);

          setLoadCareers(false);
        })
        .catch((err) => {
          setLoadCareers(false);
          setCareers([]);
        });
    }
  }
  function getCategoryName(id) {
    const categoryName = categories.find((obj) => obj.id === id);

    return categoryName.name;
  }
  return (
    <div className="aboutUs-container">
      <div id="aboutUsTop">
        <div id="topCover">
          <div>
            <p id="nice">
              {t("careers.careers")}{" "}
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
            </p>
            <p id="about">{t("careers.join")} </p>
          </div>
          <p id="aboutCompany">{t("services.text")} </p>
        </div>
      </div>
      <div id="careers">
        {loading ? (
          <div className="loaderDiv" style={{ height: "43vh" }}>
            <PuffLoader size={80} color="#3B6DEA" />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-3  careerNav">
                <p
                  onClick={() => handleActive(0, null)}
                  className={active == 0 ? "activeCareer" : null}
                >
                  {t("careers.all")}
                </p>

                {categories &&
                  categories.map((data, index) => (
                    <p
                      onClick={() => handleActive(index + 1, data.id)}
                      className={active == index + 1 ? "activeCareer" : null}
                    >
                      {data.name}
                    </p>
                  ))}
              </div>
              <div className="col-sm-9">
                <div className="container-fluid">
                  {loadCareers ? (
                    <div className="loaderDiv" style={{ height: "43vh" }}>
                      <PuffLoader size={80} color="#3B6DEA" />
                    </div>
                  ) : (
                    <div className="row">
                      <>
                        {careers.length == 0 ? (
                          <p id="noResult">{t("navbar.noResult")}</p>
                        ) : null}
                      </>
                      {careers &&
                        careers.map((data, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              history(`/job-description/${data.id}`)
                            }
                            className="col-12 careerDiv firstCareer"
                          >
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-10">
                                  <p id="careerName">{data.job_title}</p>
                                  <div
                                    className="projType careerType"
                                    id={
                                      data.job_type == "Web Development"
                                        ? "web"
                                        : data.job_type == "Design"
                                        ? "design"
                                        : data.job_type == "IOS Development"
                                        ? "ios"
                                        : data.job_type == "Android Development"
                                        ? "android"
                                        : "test"
                                    }
                                  >
                                    <BsCircleFill
                                      size={7}
                                      className="typeCircle"
                                    />
                                    <p>{getCategoryName(data.category_id)}</p>
                                  </div>
                                  <p id="careerDetail">{data.job_summary}</p>
                                  <div id="careerNumb">
                                    <p className="jobDet1">
                                      <AiOutlineClockCircle size={23} />{" "}
                                      {data.job_type}
                                      <span>
                                        <IoLocationOutline
                                          style={
                                            i18n.language == "ar"
                                              ? { marginRight: "2em" }
                                              : { marginLeft: "2em" }
                                          }
                                          size={23}
                                        />{" "}
                                        {data.job_location}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-2 alignRight">
                                  <div id="careerArrow">
                                    {console.log(data.id)}
                                    <Link
                                      className="jobBtn"
                                      to={`/job-description/${data.id}`}
                                    >
                                      {" "}
                                      <HiArrowUpRight size={25} />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Career;
