import AxiosProvider from "./AxiosProvider";
//done
export const getProjects = () => {
    return AxiosProvider.get('/get_projects',{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}
//done
export const getSingleProject = (id) => {
    return AxiosProvider.get(`/get-specific-project/${id}`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}

//done
export const getBlogs = () => {
    return AxiosProvider.get('/get-blogs',{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}
//done
export const getSingleBlog = (id) => {
    return AxiosProvider.get(`/blogs/${id}`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}

//done
export const getClients = () => {
    return AxiosProvider.get(`/get_users_clients`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}

//not found
export const getCareers = () => {
    return AxiosProvider.get(`/get-careerJobs-forUser`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}
//not found
export const getSingleCareer = (id) => {
    return AxiosProvider.get(`/get-careerJobs-details-forUser/${id}`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}


//not found
export const getCategories = () => {
    return AxiosProvider.get(`/get_categories`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}

//not found
export const getCareerByCategory = (id) => {
    return AxiosProvider.get(`/get_jobs_by_category/${id}/jobs`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}

export const getAllCareers= () => {
    return AxiosProvider.get(`/get-careerJobs-forUser`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}
//not found
export const getTeam = () => {
    return AxiosProvider.get(`/show_team_forUser`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}


export const applyAsFreelancer = (data,lang) => {
    return AxiosProvider.post('/apply-freelancer',data,{
        headers: {
            'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',

        },
        params:{
            lang:lang
        }})
}

export const applyForJob = (data,lang) => {
    return AxiosProvider.post('/apply-for-job',data,{
        headers: {
            'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',

        },
        params:{
            lang:lang
        }})
}
//not found
export const requestQuotation = (data,lang) => {
    return AxiosProvider.post('/quotation-request',data,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        },
    params:{
        lang:lang
    }})
}

export const serviceQuestion = (data,lang) => {
    return AxiosProvider.post('/service-request',data,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        },
        params:{
            lang:lang
        }})
}

export const getCareerDetails= (id) => {
    return AxiosProvider.get(`/get-careerJobs-details-forUser/${id}`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}
export const getFeedback= () => {
    return AxiosProvider.get(`/get_feedbacks_forUser`,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',

        }})
}
export const userFeedback = (data,lang) => {
    return AxiosProvider.post('/user_add_feedback',data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',

        },
        params:{
            lang:lang
        }})
}