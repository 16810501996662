import "../style/services.css";

import Footer from "../components/Footer";

import Navbar from "../components/Navbar";

import { BsArrowRightShort } from "react-icons/bs";

import uiuxD from "../images/uiuxD.svg";
import qcD from "../images/qcD.svg";
import mobileD from "../images/mobileD.svg";
import graphicD from "../images/graphicD.svg";
import webD from "../images/webD.svg";

import uiux from "../images/uiux.svg";
import graphic from "../images/graphic.svg";
import mobile from "../images/mobile.svg";
import web from "../images/web.svg";
import qc from "../images/qc.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Services() {
  const { t, i18n } = useTranslation();

  return (
    <div className="aboutUs-container">
      <div id="aboutUsTop">
        <div id="topCover">
          <div>
            <p id="nice">
              {t("services.our")}{" "}
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
            </p>
            <p id="about">{t("services.title")}</p>
          </div>
          <p id="aboutCompany">{t("services.text")}</p>
        </div>
      </div>
      <div id="teamMember">
        <div className="container-fluid service-container">
          <div className="row gy-5 ">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/service">
                <div className="square service">
                  <div>
                    <div className="dark-img">
                      <img src={mobileD} className="img-dark" />

                      <img src={mobile} className="img-light" />
                    </div>
                    <p id="squareTitle">{t("services.squareTitle")}</p>
                    <p id="squareData">{t("services.squareData")}</p>
                    <Link to="/service">
                      {" "}
                      <button>
                        {" "}
                        <BsArrowRightShort size={30} />
                      </button>
                    </Link>
                  </div>{" "}
                </div>{" "}
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/service">
                <div className="square service">
                  <div>
                    {" "}
                    <div className="dark-img">
                      <img src={uiuxD} className="img-dark" />

                      <img src={uiux} className="img-light" />
                    </div>
                    <p id="squareTitle">{t("services.squareTitle1")}</p>
                    <p id="squareData">{t("services.squareData")}</p>
                    <Link to="/service">
                      {" "}
                      <button>
                        {" "}
                        <BsArrowRightShort size={30} />
                      </button>
                    </Link>
                  </div>{" "}
                </div>{" "}
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/service">
                <div className="square service">
                  <div>
                    {" "}
                    <div className="dark-img">
                      <img src={graphicD} className="img-dark" />

                      <img src={graphic} className="img-light" />
                    </div>
                    <p id="squareTitle">{t("services.squareTitle2")}</p>
                    <p id="squareData">{t("services.squareData")}</p>
                    <Link to="/service">
                      {" "}
                      <button>
                        {" "}
                        <BsArrowRightShort size={30} />
                      </button>
                    </Link>
                  </div>{" "}
                </div>{" "}
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/service">
                <div className="square service">
                  <div>
                    {" "}
                    <div className="dark-img">
                      <img src={webD} className="img-dark" />

                      <img src={web} className="img-light" />
                    </div>
                    <p id="squareTitle">{t("services.squareTitle3")}</p>
                    <p id="squareData">{t("services.squareData")}</p>
                    <Link to="/service">
                      {" "}
                      <button>
                        {" "}
                        <BsArrowRightShort size={30} />
                      </button>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/service">
                <div className="square service">
                  <div>
                    {" "}
                    <div className="dark-img">
                      <img src={qcD} className="img-dark" />

                      <img src={qc} className="img-light" />
                    </div>
                    <p id="squareTitle">{t("services.squareTitle4")}</p>
                    <p id="squareData">{t("services.squareData")}</p>
                    <Link to="/service">
                      {" "}
                      <button>
                        {" "}
                        <BsArrowRightShort size={30} />
                      </button>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Services;
