import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./i18next";
import { Suspense } from "react";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Suspense>
);

// Wait for the entire page to load
// window.addEventListener('load', () => {
//   // Get the loading bar element
//   const loadingBar = document.querySelector('.loading-bar');

//   // Hide the loading bar when all content has loaded
//   loadingBar.style.width = '0';
// });
