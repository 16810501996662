import thirdImg from"../images/thirdImg.svg"

import fi1 from"../images/fi1.svg"
import fi2 from"../images/fi2.svg"
import fi3 from"../images/fi3.svg"

import twitter from"../images/twitter.svg"
import facebook from"../images/Facebook.svg"
import instagram from"../images/instagram.svg"
import linkedin from"../images/linkedin.svg"
import accents from"../images/accents.svg"
import review  from"../images/review.svg"
import computer  from"../images/companyComputer.png"
import bdaiatLogoWh  from"../images/bdaiatLogoWhite.png"
import bdaiatLogoBlu from"../images/bdaiatLogoBlue.png"
import elmuhaidabLogo from"../images/ElmuhaidabLogo.png"
import fixawyLogo from"../images/fixawyLogo.png"
import alromansiahLogo from"../images/alromansiahLogo.png"
import saudiLogo from"../images/SaudiLogo.png"
import astronautPhoto from"../images/astronaut.png"
import bdaiatWhiteLogo from "../images/bdaiatWLogo.png";
import review2 from"../images/review2.svg"
import rocket from"../images/rocket.png"

import about1 from"../images/about1.png"
import about2 from"../images/about2.svg"

import Footer from "../components/Footer"
import "../style/aboutUs.css";
import Navbar from "../components/Navbar";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { useTranslation } from "react-i18next";
import { useState,useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import {RxDoubleArrowRight} from "react-icons/rx";
import { Helmet } from "react-helmet-async";

function AboutUs() {

    const {t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if(JSON.parse(localStorage.getItem("language"))==="ar"){
            document.title = "نبذة عنا";
        }else {
            document.title = 'ABOUT US';
        }
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const translateY = scrollPosition * 0.5; // Adjust the multiplier as needed


    const history = useNavigate();

    const handleGoToQuotationByAboutKickOff = () =>{
        localStorage.setItem("source", 'AboutKickOffBtn')
        history('/quotation')
    }


    return(

        <div className="aboutUs-container">

            <div className={`drkImg ${currentLanguage === 'ar' ? 'arBackground' : 'enBackground'}`} style={{ 'height':'800px', transform: `translate3d(0, ${translateY}px, 0)`}}>
                <div className="drkImgContent"> 
                
                    <MessengerCustomerChat
                        pageId="317388765490895"
                        theme_color="#3B6DEA"
                        appId="6786932621387701"
                    />
                    <div className="firstDiv marginTopFirst container-fluid">
                        <div className="row ">
                            <div className="col-lg-7  alignVert" >
                                <div>
                                    <svg className="blackBck" xmlns="http://www.w3.org/2000/svg" width="1124" height="928" viewBox="0 0 1124 928" fill="none">
                                        <path d="M281.182 0H0V928H28.4096H79.4012H160.259H332.174L662.891 923.598H724.809H794.558H904.372H1124L1016.19 801.821L891.624 670.508L669.447 424.753L281.182 0Z" fill="#010F16"/>
                                    </svg>

                                    <div style={{zIndex:"1",position:"relative"}}>
                                        <p className={`mission ${currentLanguage === 'ar' ? 'arabicStyleAbout' : ''}`}>{t("about.aboutT")}</p>
                                    </div>
                                    <div style={{zIndex:"1",position:"relative"}}>
                                        <p className={`vision ${currentLanguage === 'ar' ? 'arabicStyleBdaiat' : ''}`}>{t("about.bdaiat")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutImgLogo">
                    <div className={`btnlogo ${currentLanguage === 'ar' ? 'arabicStyleBdaiatLogoWH' : ''}`}></div>
                </div>
            </div> 


            <div className="container-fluid missionDiv">
                <div className="row gy-5 missionRow">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 missionCol">
                        <div className={`mission2 ${currentLanguage === 'ar' ? 'arabicStyleMissionTwo' : ''}`}>
                            <div className="missionTitle"><h1 style={{ fontFamily: currentLanguage === 'ar' ? 'cairo' : 'Druk' }}>{t("about.title")}</h1></div>
                            <div className="missionInfo"><p><span>{t("about.name1")}&nbsp;</span>{t("about.text1")}</p></div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 visionCol">
                        <div className={`vision2 ${currentLanguage === 'ar' ? 'arabicStyleVisionTwo' : ''}`}>
                            <div className="visionTitle"><h1 style={{ fontFamily: currentLanguage === 'ar' ? 'cairo' : 'Druk' }}>{t("about.title2")}</h1></div>
                            <div className="visionInfo"><p>{t("about.text2")}</p></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid companyDiv">
                <div className="row gy-5 companyRow1">
                    <div className={`col-lg-8 col-md-8 col-sm-12 col-xs-12 compTitleCol  ${currentLanguage === 'ar' ? 'arabicStyleCompTitleCol' : ''}`}>
                        <div className="companyTitle">
                            <div className="compTitle1"><h1 className={`${currentLanguage === 'ar' ? 'arabicStyleCompTitle1' : ''}`}>{t("about.title3")}</h1></div>
                            <div className="compTitle2"><h1 className={`${currentLanguage === 'ar' ? 'arabicStyleCompTitle2' : ''}`}>{t("about.title4")}</h1></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 compImgCol">
                        <div className="companyImg">
                            <div className="compImage">
                                <img id="computerImg" src={computer}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row gy-5 companyRow2">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 compColCard1">
                        <div className="compCard1">
                            <h5>establishment</h5>
                            <p>14</p>
                            <h5>2015</h5>
                        </div>
                        <div></div>
                        <p></p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 compColCard1">
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 compColCard1">
                    </div>
                </div> */}
                <div class="grid-container companyGrid">

{/* card 1 */}
                    <div class="grid-item">
                        <div class="item item-large" style={{marginLeft : currentLanguage === 'ar' ? '5px': '0px' , marginRight : currentLanguage === 'ar' ? '0px': '5px'}}>
                        {/* <!-- Content for the large item --> */}
                            <div className="compCard1">
                                <h5 id="cardTitle">{t("about.cardTitle")}</h5>
                                <p style={{fontFamily : currentLanguage === 'ar' ? 'Druk': 'Druk'}}>{t("about.cardNumber")}</p>
                                {/* <div className='cardNumber'>{t("about.cardNumber")}</div> */}
                                <h5 id="cardYear">{t("about.cardYear")}</h5>
                            </div>
                            <div className="compCardInfo">
                                <p style={{fontFamily : currentLanguage === 'ar' ? 'DrukText !impotrant': 'DrukText'}}>{t("about.cardInfo")}</p>
                            </div>
                        </div>
                        <div class="item-small-container"> 
                            <div class="item item-small companyItemOne"> 
                                {/* <!-- Content for the first small item --> */}
                                <div className="compCardSmImage">
                                    <img id="logoWhiteImg" src={bdaiatLogoWh}/>
                                </div>
                                <p className={`${currentLanguage === 'ar' ? 'arabicStyleCompTitle1p' : ''}`}  id="compTit1">BUSSINESS</p>
                                <p className={`${currentLanguage === 'ar' ? 'arabicStyleCompTitle2p' : ''}`} id="compTit2">STARTING</p>
                            </div>
                            <div class="item item-small companyItemTwo"> 
                                {/* <!-- Content for the second small item --> */}
                                <p id="compTit3">{t("about.EngNum1")}</p>
                                <p id="compTit4">{t("about.Engs1")}</p>
                            </div>
                        </div>
                    </div>

{/* card 2 */}
                    <div class="grid-item">
                        <div class="item item-large" style={{marginLeft : currentLanguage === 'ar' ? '5px': '0px' , marginRight : currentLanguage === 'ar' ? '0px': '5px'}}> 
                        {/* <!-- Content for the large item --> */}
                            <div className="compCard2">
                                <h5 id="cardTitle">{t("about.cardTitle2")}</h5>
                                <div className={`compCardSmImage2 ${currentLanguage === 'ar' ? 'arabicStyleCompCardSmImage2' : ''}`}>
                                    <img id="logoBlueImg" src={bdaiatLogoBlu}/>
                                </div>
                                <p className={`${currentLanguage === 'ar' ? 'arabicStyleCompCard2p' : ''}`}>{t("about.cardNumber2")}</p>
                                <h5 id="cardYear">{t("about.cardYear2")}</h5>
                            </div>
                            <div className="compCardInfo2">
                                <p>{t("about.cardInfo2")}</p>
                            </div>
                        </div>
                        <div class="item-small-container"> 
                            <div class="item item-small item2 companyItemThree"> 
                                {/* <!-- Content for the first small item --> */}
                                <div className="compCardSmImage3">
                                    <img id="logoElmuhaidab" src={elmuhaidabLogo}/>
                                </div>
                                <p id="compTit1"  style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>Al MUHAIDIB</p>
                            </div>
                            <div class="item item-small companyItemFour"> 
                                {/* <!-- Content for the second small item --> */}
                                <p id="compTit3">{t("about.EngNum2")}</p>
                                <p id="compTit4">{t("about.Engs2")}</p>
                            </div>
                        </div>
                    </div>

{/* card 3 */}
                    <div class="grid-item">
                        <div class="item item-large" style={{marginLeft : currentLanguage === 'ar' ? '5px': '0px' , marginRight : currentLanguage === 'ar' ? '0px': '5px'}}> 
                        {/* <!-- Content for the large item --> */}
                            <div className="compCard3">
                                <h5 id="cardTitle">{t("about.cardTitle3")}</h5>
                                <div className={`compCardSmImage2 ${currentLanguage === 'ar' ? 'arabicStyleCompCardSmImage2' : ''}`}>
                                    <img id="logoBlueImg" src={bdaiatLogoBlu}/>
                                </div>
                                <p className={`${currentLanguage === 'ar' ? 'arabicStyleCompCard3p' : ''}`}>{t("about.cardNumber3")}</p>
                                <h5 id="cardYear">{t("about.cardYear3")}</h5>
                            </div>
                            <div className="compCardInfo">
                                <p>{t("about.cardInfo3")}<br/>{t("about.cardInfo4")} </p>
                            </div>
                        </div>
                        <div class="item-small-container"> 
                            <div class="item item-small companyItemFive"> 
                                {/* <!-- Content for the first small item --> */}
                                <div className="compCardSmImag4"  style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>
                                    <img id="logoFixawy" src={fixawyLogo}/>
                                </div>
                                <p id="compTit7"  style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>FIXAWY</p>
                            </div>
                            <div class="item item-small companyItemSix"> 
                                {/* <!-- Content for the second small item --> */}
                                <p id="compTit5">{t("about.EngNum3")}</p>
                                <p id="compTit6">{t("about.Engs3")}</p>
                            </div>
                        </div>
                    </div>

{/* card 4 */}
                    <div class="grid-item">
                        <div class="item item-large" style={{marginLeft : currentLanguage === 'ar' ? '5px': '0px' , marginRight : currentLanguage === 'ar' ? '0px': '5px'}}> 
                        {/* <!-- Content for the large item --> */}
                            <div className="compCard4">
                                <h5 id="cardTitle">{t("about.cardTitle4")}</h5>
                                <div className={`compCardSmImage5 ${currentLanguage === 'ar' ? 'arabicStyleCompCardSmImage5' : ''}`}>
                                    <img id="logoBlueImg" src={bdaiatLogoBlu}/>
                                </div>
                                <p className={`${currentLanguage === 'ar' ? 'arabicStyleCompCard4p' : ''}`}>{t("about.cardNumber4")}</p>
                                <h5 id="cardYear">{t("about.cardYear4")}</h5>
                            </div>
                            <div className="compCardInfo2">
                                <p>{t("about.cardInfo5")}<br/>{t("about.cardInfo6")} </p>
                            </div>
                        </div>
                        <div class="item-small-container noGrid"> 
                            <div class="item item-small companyItemSeven"> 
                                {/* <!-- Content for the first small item --> */}
                                <div className="compCardSmImag6" style={{textAlign : currentLanguage === 'ar' ? 'left': 'right'}}>
                                    <img id="logoAlromansiah " src={alromansiahLogo}/>
                                </div>
                                <p id="compTit10" style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>AL</p>
                                <p id="compTit11" style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>ROMANSIAH</p>
                            </div>
                            <div class="item item-small companyItemEight"> 
                                {/* <!-- Content for the second small item --> */}
                                <p id="compTit8">{t("about.EngNum4")}</p>
                                <p id="compTit9">{t("about.Engs4")}</p>
                            </div>
                        </div>
                    </div>

{/* card 5 */}
                    <div class="grid-item">
                        <div class="item item-large" style={{marginLeft : currentLanguage === 'ar' ? '5px': '0px' , marginRight : currentLanguage === 'ar' ? '0px': '5px'}}> 
                        {/* <!-- Content for the large item --> */}
                            <div className="compCard5">
                                <h5 id="cardTitle">{t("about.cardTitle5")}</h5>
                                <div className={`compCardSmImage2 ${currentLanguage === 'ar' ? 'arabicStyleCompCardSmImage2' : ''}`}>
                                    <img id="logoBlueImg" src={bdaiatLogoBlu}/>
                                </div>
                                <p className={`${currentLanguage === 'ar' ? 'arabicStyleCompCard2p' : ''}`}>{t("about.cardNumber5")}</p>
                                <h5 id="cardYear">{t("about.cardYear5")}</h5>
                            </div>
                            <div className="compCardInfo2">
                                <p>{t("about.cardInfo7")}<br/>{t("about.cardInfo8")} </p>
                            </div>
                        </div>
                        <div class="item-small-container noGrid"> 
                            <div class="item item-small item2 companyItemNine"> 
                                {/* <!-- Content for the first small item --> */}
                                <div className="compCardSmImage7" style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>
                                    <img id="logoSaudi" src={saudiLogo}/>
                                </div>
                                <p id="compTit14" style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>KSA</p>
                            </div>
                            <div class="item item-small companyItemTen"> 
                                {/* <!-- Content for the second small item --> */}
                                <p id="compTit12">{t("about.EngNum5")}</p>
                                <p id="compTit13">{t("about.Engs5")}</p>
                            </div>
                        </div>
                    </div>

{/* card 6 */}
                    <div class="grid-item lastCard">
                        <div class="item item-large lastItem"> 
                        {/* <!-- Content for the large item --> */}
                            <div className="compCard6">
                                <div className="astroImage">
                                    <img id="logoAstro" src={astronautPhoto}/>
                                </div>
                            </div>
                        </div>
                        <div class="item-small-container item3 noGrid"> 
                        {/* <!-- Content for the large item --> */}
                            <div  className={`compCard7 ${currentLanguage === 'ar' ? 'arabicStyleCompCard7' : ''}`}>
                                <p id="compTit15" style={{paddingRight : currentLanguage === 'ar' ? '0px': '35px'}}>{t("about.cardNumber6")}</p>
                            </div>
                            <div className="compCardInfo3">
                                <p>{t("about.cardInfo9")}</p>
                            </div>
                        </div>
                    </div>
                {/* <!-- Repeat the structure for additional rows as needed --> */}
                </div>
            </div>


            <div className={`container-fluid process4 ${currentLanguage === 'ar' ? 'processFlib-background2' : ''}`}>
                <div className="row ">
                    <div className={`col-lg-8 col-md-8 col-sm-12 col-xs-12 alignVert2 ${currentLanguage === 'ar' ? 'alignVertArabicStyle' : ''}`}>
                    <div>
                        <div style={{ zIndex: "1", position: "relative" }}>
                        <p id="turnIdeas">
                            {t("processPage.ready")} 
                        </p>
                        <p id="powerful2">{t("processPage.ready2")} </p>
                        </div>
                        <div className="btnsDiv">
                            {/* <Link to="/quotation">
                                    <button className={`talk ${currentLanguage === 'ar' ? 'arabicStyleTalk' : ''}`}> 
                                    {t("processPage.talkBtn")}  <img className="btnlogo" src={bdaiatWhiteLogo} />
                                    </button>{" "}
                            </Link> */}
                            <button 
                                onClick={() => handleGoToQuotationByAboutKickOff()}
                                className={`talk ${currentLanguage === 'ar' ? 'arabicStyleTalk' : ''}`}> 
                                    {t("processPage.talkBtn")}  
                                <img className="btnlogo" src={bdaiatWhiteLogo} />
                            </button>{" "}
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col5Responsive">
                        <div className="processImgLogo">
                        <div className={`btnlogo ${currentLanguage === 'ar' ? 'arabicStyleBdaiatLogoWH' : ''}`}></div>
                    </div>
                    </div>
                </div>
            </div>
            {/* <div id="aboutUsTop">
                <div id="topCover">
                    <div>
                        <p id="nice">{t("about.nice")} <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                        <p id="about">{t("about.about")}</p>
                    </div>
                    <p id="aboutCompany">{t("services.text")}</p>
                </div>
            </div> */}
            {/* <div id="aboutUspoint1">
                <div>
                    <div className="compImg"  style={{backgroundImage:`url(${about1})`}}></div>
                </div>
                <div className="container-fluid aboutTxt" style={{padding:"96px 0"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <div>
                                <p id="nice">{t("about.us")} <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                <p id="flexible">{t("about.provide")} </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p style={{maxWidth:"590px"}} id="aboutCompany">{t("about.since")} </p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" >
                    <div className="row gy-5 contDiv-flex">
                        <div className="col-lg-4 col-sm-6 noPadding">
                            <div className="our">
                                <div className="fi"  id="fit1" style={{backgroundImage:`url(${fi1})`}}></div>
                                    <p id="history">{t("about.history")} </p>
                                    <p id="historyData">{t("about.since2")} </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 noPadding">
                            <div className="our">
                                <div className="fi" id="fit2" style={{backgroundImage:`url(${fi2})`}}></div>
                                    <p id="history">{t("about.mission")} </p>
                                    <p id="historyData">{t("about.since2")} </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 noPadding">
                            <div className="our">
                                <div className="fi"  id="fit3" style={{backgroundImage:`url(${fi3})`}}></div>
                                <p id="history">{t("about.visson")} </p>
                                <p id="historyData">{t("about.since2")} </p>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            <div>
                <div className="whyUs whyUs-about">
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-md-6 vertAlign">
                                <div>
                                    <p id="nice">{t("about.choose")} <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                    <p style={{maxWidth:"500px",fontWeight:"700"}} id="flexible">{t("about.custom")}</p>
                                    <p style={{maxWidth:"590px"}} id="aboutCompany">{t("about.made")}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div style={{width:"fir-content",marginLeft:"auto"}}>
                                    <div style={{textAlign:"center"}}>
                                        <div id="img1" style={{backgroundImage:`url(${about2})`}}>
                                        </div>
                                        <div id="img2" style={{backgroundImage:`url(${about2})`}}>
                                        </div>
                                    </div>
                                    <div className="imgsRow2" >
                                        <div id="img3" style={{backgroundImage:`url(${about2})`}}>
                                        </div>
                                        <div id="img4" style={{backgroundImage:`url(${about2})`}}>
                                        </div>
                                        <div id="img5" style={{backgroundImage:`url(${about2})`}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </div>
    )
}

export default AboutUs