import axios from 'axios'


export default axios.create({
    baseURL: 'https://test.bdaiat.com/api',
    // baseURL: 'http://bdaiat.local:8000/api',
    // baseURL: 'http://192.168.1.8:8000/api',
    headers: {

        'Content-type': 'application/json'
    }
}) 