import "../style/careers.css";

import Footer from "../components/Footer"
import { BsCircleFill } from "react-icons/bs";

import Navbar from "../components/Navbar";
 import { useParams } from "react-router";
 import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { RiHome6Line } from "react-icons/ri";
import { VscTriangleRight } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "../components/Modal";
import { PuffLoader } from "react-spinners";
import { getCareerDetails } from "../api";
import { AiOutlineClockCircle } from "react-icons/ai";
import {IoLocationOutline} from "react-icons/io5";
import Success from "../components/Success";
function JobDescription() {
    const { t,i18n } = useTranslation();
const[modal,setModal]=useState(false)
const[job,setJob]=useState()
const [loading,setLoading]=useState(true)
const [success,setSuccess]=useState(false)

const { id } = useParams();

useEffect(()=>{
    getCareerDetails(id).then((response) => {
        setJob(response.data.data)
            setLoading(false)
    
        } )
    },[])

return(



    <div className="aboutUs-container">
        {modal?<Modal setSuccess={setSuccess} type="job" id={id} name={job.job_title} setModal={setModal}/>:null}
        {success?<Success type="job" setModal={setSuccess}/>:null}

{loading?  <div className="loaderDiv" style={{height: "50vh"}}>

<PuffLoader size={80} color="#3B6DEA"/>

</div>:
<>
<div className="mobileNav">
<p id="aboutNav"><Link to="/"><RiHome6Line size={20} style={{color:" #1D2939"}}/></Link> <IoIosArrowForward size={25} style={{margin:"0 0.5em"}}/> <Link to="/services" style={{textDecoration:"none"}}><span style={{color:" #1D2939"}}>{t("careers.careers")}</span></Link> </p>
</div>
<div  id="aboutUsTop">
    <div id="topCover">
<div>
<p id="nice">{t("careers.careers")}  <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
<p id="about">{t("careers.join")}</p>
<p id="aboutNav"><Link to="/"><RiHome6Line size={20} style={{color:" #1D2939"}}/></Link> <IoIosArrowForward size={25} style={{margin:"0 0.5em"}}/> <Link to="/career" style={{textDecoration:"none"}}><span style={{color:" #1D2939"}}>{t("careers.careers")}</span> </Link><IoIosArrowForward  style={{margin:"0 0.5em"}} size={25}/> <span>{t("careers.details")}</span> </p>
</div>
<p id="aboutCompany">{t("services.text")} </p>
</div>
</div>

<div className="jobDescribe">
    <div className="flexBetween">
    <p id="jobName">{job.job_title}</p>

    </div>
    <p id="jobDesc">{t("careers.desc")} </p>

    <p className="jobDet"><AiOutlineClockCircle size={20}/> {job.job_type} 
    <span>

    <IoLocationOutline style={i18n.language=="ar"?{marginRight:"2em"}:{marginLeft:"2em"}} size={20}/> {job.job_location}
    </span>
    </p>


<p id="jobDetail">{job.job_summary}
</p>


<p style={{marginTop:"32px"}} id="jobDesc">{t("careers.req")} </p>
<p id="jobDetail">{job.requirements}</p>

{/*
<p style={{marginTop:"2em"}} id="jobDesc">{t("careers.benefits")} </p>

<div className="teamLi">
    <p><VscTriangleRight size={25}/>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
    <p><VscTriangleRight size={25}/>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
    <p><VscTriangleRight size={25}/>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
</div>*/}

   <button onClick={()=>setModal(true)} className="request apply">{t("careers.apply")} </button>
</div>
</>}
<Footer/>
    </div>
)
}

export default JobDescription