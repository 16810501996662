import "../style/projects.css";

import Footer from "../components/Footer"
import { BsCircleFill } from "react-icons/bs";

import Navbar from "../components/Navbar";
import Alromansiah  from"../images/Alromansiah.svg"
import { useEffect,useState } from "react";
import { useParams } from "react-router";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineOtherHouses } from "react-icons/md";
import { VscTriangleRight } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getSingleProject } from "../api";
import { PuffLoader } from "react-spinners";
import {RiHome6Line} from "react-icons/ri";

function ProjectDetails() {
    const { t,i18n } = useTranslation();
    const { id } = useParams();
    const [loading,setLoading]=useState(true)

    const [project,setProject]=useState([])

    useEffect(()=>{
        getSingleProject(id).then((response) => {

            setProject(response.data.data)
                setLoading(false)
            }

            )
        },[])

return(



    <div className="aboutUs-container">

{loading?  <div className="loaderDiv" style={{height: "50vh"}}>

<PuffLoader size={80} color="#3B6DEA"/>

</div>:
<>

<div className="mobileNav">
<p id="aboutNav" ><Link to="/"><RiHome6Line size={20} style={{color:" #1D2939"}}/></Link> <IoIosArrowForward size={25} style={{margin:"0 0.5em"}}/> <Link to="/projects" style={{textDecoration:"none"}}><span style={{color:" #1D2939"}}>{t("project.projects")}</span> </Link><IoIosArrowForward  style={{margin:"0 0.5em"}} size={25}/> <span>{project.name}</span> </p>
</div>
 <div className="projImage" style={{backgroundImage:`url(${project.pic})`}}>

 </div>

<div className="jobDescribe">
<p id="aboutNav" style={{marginBottom:"32px"}}><Link to="/"><RiHome6Line size={20} style={{color:" #1D2939"}}/></Link> <IoIosArrowForward size={25} style={{margin:"0 0.5em"}}/> <Link to="/projects" style={{textDecoration:"none"}}><span style={{color:" #1D2939"}}>{t("project.projects")}</span> </Link><IoIosArrowForward  style={{margin:"0 0.5em"}} size={25}/> <span>{project.name}</span> </p>

<div className="flexBetween" style={{justifyContent:"initial"}}>
    <p id="jobName">{project.name}</p>
   
    </div>

<p id="jobDetail">
{project.description}
</p>


{/*
<p style={{marginTop:"2em"}} id="jobDesc">{t("project.process")}</p>

<div className="teamLi">
    <p><VscTriangleRight size={25}/>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
    <p><VscTriangleRight size={25}/>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
    <p><VscTriangleRight size={25}/>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
</div>
*/}
</div>
</>}
<Footer/>
    </div>
)
}

export default ProjectDetails