import "../style/team.css";

import Footer from "../components/Footer"

import Navbar from "../components/Navbar";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import about2 from"../images/about2.svg"
import avatar from"../images/Avatar.svg"
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper";
import {BsArrowRightShort} from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { GoDash } from "react-icons/go";
import { PuffLoader } from "react-spinners";

import { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import { getTeam } from "../api";

function Team2() {
    const { t,i18n } = useTranslation();
const [teamMembers,setTeamMembers]=useState([])
const [loading,setLoading]=useState(true)
    useEffect(()=>{
        getTeam().then((response) => {
    
            setTeamMembers(response.data.data)
            setLoading(false)
            }
    
            ).catch((err)=>{
                setLoading(false)
            })
        },[])

return(



    <div className="aboutUs-container">


<div id="aboutUsTop">
<div id="topCover">

<div>
<p id="nice">{t("careers.join")}  <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
<p id="about">{t("team.meet")}</p>
</div>
<p id="aboutCompany">{t("services.text")}</p>
</div></div>

<div id="teamMember">
   
<div className="container-fluid teamDiv" >
    
        <div className="row " >
        {loading?  <div className="loaderDiv" style={{height: "43vh"}}>

<PuffLoader size={80} color="#3B6DEA"/>

</div>:
<>
            {teamMembers && teamMembers.map((data,index)=>
            
            <div className="col-md-4 col-sm-6" style={{marginBottom:"117px"}} key={index}>
          <div className="team-padding">

      
            <div className="img-gradient">
            <div id="member" style={{backgroundImage:`url(${data.image})`}}>
                <div></div>
                <div style={{zIndex:"10"}}>
                
               <div id="memberData">
                   <p id="memberName">{data.name}</p>
                   <p  id="memberPos">{data.job_title}</p>
               </div></div>
              
</div></div>
</div>
            </div>

            )}</>}
           


           
          

</div></div>
 



<Swiper navigation={true} 
modules={[Navigation]} 
breakpoints={{
    0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      450: {
      slidesPerView: 2,
      spaceBetween: 20,
    }
  }}


spaceBetween={30}
className="mySwiper teamSwiper">
    {teamMembers && teamMembers.map((data,index)=>
        <SwiperSlide key={index}>
        <div className="img-gradient">
            <div id="member" style={{backgroundImage:`url(${data.image})`}}>
                <div></div>
                <div style={{zIndex:"10"}}>
                <div id="memberSocials">
                 <button><AiOutlineTwitter size={20}/></button>
                 <button id="lin"><FaLinkedinIn size={20}/></button>

                 <button id="fb"><FaFacebookF size={20}/></button>
         
                

               </div>
               <div id="memberData">
               <p id="memberName">{data.name}</p>
               <p  id="memberPos">{data.job_title}</p>
               </div></div>
              
</div></div></SwiperSlide>)}


      
       
    
      </Swiper>




</div>
<div>
<div className="whyUs teamGet" >
<div className="container-fluid" >
        <div className="row">
            <div className="col-md-6 vertAlign">
            <div>
<p id="nice">{t("team.know")} <span>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
<p style={{maxWidth:"500px",fontWeight:"700"}} id="flexible">{t("team.start")}</p>
<p style={{maxWidth:"590px"}} id="aboutCompany">{t("team.bdaiat")}</p>
<button className="request">{t("team.hiring")}</button>
</div>
            </div>
            <div className="col-md-6 teamImg">
                <div style={{width:"fir-content",marginLeft:"auto"}}>
<div style={{textAlign:"center"}}>
<div id="img1" style={{backgroundImage:`url(${about2})`}}>
</div>
<div id="img2" style={{backgroundImage:`url(${about2})`}}>
</div>
</div>
<div className="imgsRow2" >
<div id="img3" style={{backgroundImage:`url(${about2})`}}>
</div>
<div id="img4" style={{backgroundImage:`url(${about2})`}}>
</div>
<div id="img5" style={{backgroundImage:`url(${about2})`}}>
</div>
</div>
</div>
</div>
        </div>
    </div></div>
</div>
<Footer/>
    </div>
)
}

export default Team2