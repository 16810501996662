import "../style/projects.css";

import Footer from "../components/Footer"
import { BsCircleFill } from "react-icons/bs";

import Navbar from "../components/Navbar";
import Alromansiah  from"../images/Alromansiah.svg"
import { useEffect,useState } from "react";
import { useParams } from "react-router";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineOtherHouses } from "react-icons/md";
import { VscTriangleRight } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getSingleBlog } from "../api";
import { PuffLoader } from "react-spinners";
import {RiHome6Line} from "react-icons/ri";

function BlogDetails() {
    const { t,i18n } = useTranslation();
    const { id } = useParams();
    const [loading,setLoading]=useState(true)

    const [blog,setBlog]=useState([])

    useEffect(()=>{
        getSingleBlog(id).then((response) => {

            setBlog(response.data.data)
                setLoading(false)
                // Set document title here
                document.title = blog.title;
            })
        },[])

    const stripTags = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || '';
    };



    return(

        <div className="aboutUs-container">

          {loading?  <div className="loaderDiv" style={{height: "50vh"}}>

          <PuffLoader size={80} color="#3B6DEA"/>

          </div>:
          <>

          <div className="mobileNav">
          <p id="aboutNav" ><Link to="/"><RiHome6Line size={20} style={{color:" #1D2939"}}/></Link> <IoIosArrowForward size={25} style={{margin:"0 0.5em"}}/> <Link to="/blogs" style={{textDecoration:"none"}}><span style={{color:" #1D2939"}}>{t("blog.projects")}</span> </Link><IoIosArrowForward  style={{margin:"0 0.5em"}} size={25}/> <span>{blog.title}</span> </p>
          </div>
          <div className="projImage" style={{backgroundImage:`url(${blog.image})`}}>

          </div>

          <div className="jobDescribe">
            <p id="aboutNav" style={{marginBottom:"32px"}}><Link to="/"><RiHome6Line size={20} style={{color:" #1D2939"}}/></Link> <IoIosArrowForward size={25} style={{margin:"0 0.5em"}}/> <Link to="/blogs" style={{textDecoration:"none"}}><span style={{color:" #1D2939"}}>{t("blog.projects")}</span> </Link><IoIosArrowForward  style={{margin:"0 0.5em"}} size={25}/> <span>{blog.title}</span> </p>

            <div className="flexBetween" style={{justifyContent:"initial"}}>
                {/* <p id="jobName">{blog.title}</p> */}
              
                </div>
                <p id="jobName">{blog.date}</p>

            {/* <p id="jobDetail">{stripTags(blog.description)}</p> */}
            <div className="blogsArea">
              <p id="jobDetail" dangerouslySetInnerHTML={{ __html: blog.description }}/>
            </div>
          </div>

          </>}
          <Footer/>
        </div>
    )
}

export default BlogDetails